import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Alerts } from 'src/app/shared/enums/alerts';
import { TextBlock } from 'src/app/shared/models/textBlock';
import { SharedService } from 'src/app/shared/shared.service';
import { TextBlockBase } from '../text-block-base';
import { TextBlocksService } from '../text-blocks.service';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-create-text-block',
    templateUrl: './create-text-block.component.html',
    styleUrls: ['./create-text-block.component.scss']
})
export class CreateTextBlockComponent extends TextBlockBase implements OnInit {

    options: UntypedFormGroup;
    textBlock: TextBlock = new TextBlock();
    loading: boolean = false;
    
    constructor(private fb: UntypedFormBuilder,
                private textBlocksService: TextBlocksService, 
                public dialogRef: MatDialogRef<CreateTextBlockComponent>,
                protected sharedService: SharedService) {
        super();
        this.options = this.fb.group({
            hideRequired: false,
            floatLabel: 'auto',
        });
    }

    ngOnInit() {
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }

    createTextBlock() {
        if(this.isInValidData(this.textBlock)) {
            return;
        }

        this.loading = true;
        this.textBlocksService.createTextBlock(this.textBlock).subscribe(
            data => {
                this.loading = false;
                this.sharedService.showAlert(Alerts.SUCCESS, 'Text Block created successfully');
                this.dialogRef.close(true);
            },
            error => {
                this.loading = false;
                this.sharedService.handleErrors(error,
                    [
                        {"condition": (error.status == 400 && error.error), "msg": error.error}
                    ], "Unable to create Text Block");
            }
        );
    }

    resetTextBlock() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.textBlock = new TextBlock();
                    this.fieldcheck = false;
                }
            });
        } else {
            this.textBlock = new TextBlock();
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }
}
