import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntitlementsService } from '../entitlements.service';
import { SharedService } from '../../shared/shared.service';
import { Alerts } from '../../shared/enums/alerts';
import { UsersService } from "../../users/users.service";
import { Roles } from "../../shared/enums/roles";
import { HomeService } from '../../home/home.service';
import { EntitlementBase } from '../entitlement-base';
import { EntitlementTo } from 'src/app/shared/models/entitlementTo';
import { SearchTypeTo } from 'src/app/shared/models/searchTypeTo';
import { SearchTypesService } from 'src/app/search-types/search-type.service';
import { PreferenceTo } from 'src/app/shared/models/preferenceTo';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-update-entitlements',
    templateUrl: './update-entitlements.component.html',
    styleUrls: ['./update-entitlements.component.scss']
})
export class UpdateEntitlementsComponent extends EntitlementBase implements OnInit {

    resetData: EntitlementTo;
    roles = Roles;
    header = "Update Entitlement";
    title: string;
    searchTypeList: Array<SearchTypeTo> = [];
    preferenceList: Array<PreferenceTo> = [];
    selectedPreferences: string[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) private data: EntitlementTo,
                public dialogRef: MatDialogRef<UpdateEntitlementsComponent>,
                protected sharedService: SharedService,
                private entitlementsService: EntitlementsService,
                public usersService: UsersService,
                public homeService: HomeService,
                private searchTypesService: SearchTypesService) {
        super();   
    }

    ngOnInit() {
        this.getAllSearchTypes();
        this.getAllPreferences();
        this.entitlement = new EntitlementTo(this.data);
        this.resetData = new EntitlementTo(this.data);
        this.title = this.entitlement.displayName;
    }

    getAllSearchTypes() {
        this.searchTypesService.getAllSearchTypes().subscribe(data => {
            this.searchTypeList = data;
        }, error => {
            this.sharedService.showAlert(Alerts.ERROR, 'Failed to fetch SearchTypes to check mappings');
        });
    }

    getAllPreferences() {
        this.isLoading = true;
        this.entitlementsService.getAllPreferences().subscribe(data => {
            this.preferenceList = data;
            this.preferenceList.sort((a,b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1);
            this.isLoading = false;
        }, error => {
            this.sharedService.showAlert(Alerts.ERROR, 'Failed to fetch preferences');
            this.isLoading = false;
        });
    }

    updateEntitlements() {
        if (this.name.valid && this.displayName.valid && this.entitlement.orgType) {
            if (this.resetData.displayName !== this.entitlement.displayName ||
                    this.resetData.orgType !== this.entitlement.orgType || this.isPreferenesModified()) {
                this.isLoading = true;
                this.updateEntitlementWithSearchType();
            } else {
                this.sharedService.showAlert(Alerts.ERROR, 'No changes have been made');
                this.markFormControlsAsTouched();
            }
        }
    }

    isPreferenesModified() {
        if(this.resetData.preferences.length !== this.entitlement.preferences.length) {
            return true;
        }
        for (let preference of this.entitlement.preferences) {
            if (!this.resetData.preferences.includes(preference)) {
                return true;
            }
        }
        return false;
    }

    updateEntitlementWithSearchType() {
        let mappedSearchTypes: Array<string> = [];
        this.searchTypeList.forEach(searchType => {
            let isMapped: boolean = false;
            if (searchType.entitlement === this.resetData.name) {
                isMapped = true;
            }
            if (isMapped) {
                mappedSearchTypes.push(searchType.name);
            }
        });

        if (mappedSearchTypes.length === 0) {
            this.entitlementsService.updateEntitlement(this.entitlement).subscribe(data => {
                this.isLoading = false;
                this.sharedService.showAlert(Alerts.SUCCESS, 'Entitlement updated successfully');
                this.dialogRef.close(true);
            }, error => {
                this.isLoading = false;
                this.sharedService.handleErrors(error,
                    [{
                    "condition": (error.status === 400 && Object.keys(error.error).length > 0),
                    "msg": error.error[Object.keys(error.error)[0]]
                    }],
                    "Unable to update entitlement");
            });
        } else {
            this.isLoading = false;
            this.dialogRef.close(false);
            this.sharedService.showConfirmAlert('error', "Could not update entitlement as it has already been mapped to below search types, [" + mappedSearchTypes + "]");
        }
    }

    resetEntitlement() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.entitlement = new EntitlementTo(this.resetData);
                }
            });
        } else {
            this.entitlement = new EntitlementTo(this.resetData);
        }
    }

    close() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

}