import {UntypedFormControl, Validators} from "@angular/forms";
import {Patterns} from "../shared/enums/patterns";

/**
 * Common base class for create and update org components.
 */
export abstract class OrganizationBase {
    
    readonly NAME_MIN_LENGTH = 5;
    readonly NAME_MAX_LENGTH = 512;
    readonly CLIENT_ID_MIN_LENGTH = 8;
    readonly CLIENT_ID_MAX_LENGTH = 20;

    readonly NAME = 'name';
    readonly CLIENT_ID = 'clientId';
    readonly PARENT_ID = 'parentId'

    name = new UntypedFormControl('', [
        Validators.minLength(this.NAME_MIN_LENGTH), Validators.maxLength(this.NAME_MAX_LENGTH)
    ]);
    clientId = new UntypedFormControl('', [
        Validators.minLength(this.CLIENT_ID_MIN_LENGTH), Validators.maxLength(this.CLIENT_ID_MAX_LENGTH),
        Validators.pattern(Patterns.ALPHANUMERIC)
    ]);
    parentId = new UntypedFormControl('', []);

    parentOrgName: string;
    fieldcheck: boolean = false;
    loading: boolean = false;
    count: number = 1;

    constructor() {}

    markFormControlsAsTouched() {
        this.name.markAsTouched();
        this.clientId.markAsTouched();
    }

    getErrorMessage(formControl) {
        if (formControl === this.NAME) {
            return this.name.hasError('required') ? 'You must enter a value' : 'Enter a valid name (5-512 characters)';
        } else if (formControl === this.CLIENT_ID) {
            return this.clientId.hasError('required') ? 'You must enter a value' : 'Enter a valid Client Id (8-20 alphanumeric values)';
        }
    }

    change(event) {
        if(event) {
            if (event.srcElement.value !== '') {
                this.count = this.count + 1;
            } else if (event.srcElement.value === '') {
                this.count = this.count - 1;
            }
        }
        if (this.count <= 1 || !this.fieldcheck) {
            this.fieldcheck = true;
        }
    }

}
