import {Injectable} from '@angular/core';
import {OrganizationTo} from '../shared/models/organizationTo';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from '../../assets/config/config-svc.service';
import {SharedService} from '../shared/shared.service';
import {map} from 'rxjs/operators';
import { EntitlementTo } from '../shared/models/entitlementTo';
import {Page} from "puppeteer";


@Injectable({
    providedIn: 'root'
})
export class OrganizationsService {
    updateOrganizationList = new Subject<void>();
    updateOrganizationList$ = this.updateOrganizationList.asObservable();

    constructor(public http: HttpClient, public sharedService: SharedService) {
    }

    getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true
        };
        return httpOptions;
    }

    public doesOrgExist(clientId): Observable<OrganizationTo> {
        return this.http.get<OrganizationTo>(ConfigService.config.userServiceUrl + '/organization/exists/?CLIENT_ID=' + clientId,
            this.getHeaders()).pipe(map((bool => (bool))));
    }

    public getOrg(orgUuid) {
        return this.http.get<any>(ConfigService.config.userServiceUrl + '/organization/' + orgUuid,
            this.getHeaders()).pipe(map(res => (res)));
    }

    public getOrgByClientId(clientId) {
        return this.http.get<any>(ConfigService.config.userServiceUrl + '/organizationByClientId/' + clientId,
            this.getHeaders()).pipe(map(res => (res)));
    }

    public getOrganizations(filterValue, sortActive, sortDirection, orgTypes, pageIndex, pageSize): Observable<Page> {
        return this.http.get<Page>(ConfigService.config.userServiceUrl + '/organizations?SEARCH_TERM=' + encodeURIComponent(filterValue) + '&sortActive=' + sortActive + '&sortDirection=' + sortDirection + '&orgTypes=' + orgTypes + '&page=' + pageIndex + '&size=' + pageSize,
            this.getHeaders()).pipe(map((orgs => orgs)));
    }

    public getAllOrganizationsSettings(clientId: String) {
        return this.http.get(ConfigService.config.userServiceUrl + '/organizationPreferences?CLIENT_ID=' + clientId,
            this.getHeaders()).pipe(map((orgs => (orgs))));
    }

    public getServiceEntitlementsForOrg(clientId) {
        return this.http.get(ConfigService.config.userServiceUrl + '/organizationServiceEntitlementsByClientId?CLIENT_ID=' + clientId,
            this.getHeaders()).pipe(map(res => (res)));
    }

    public getServicePreferencesForOrg(clientId) {
        return this.http.get(ConfigService.config.userServiceUrl + '/organizationServicePreferencesByClientId?CLIENT_ID=' + clientId,
            this.getHeaders()).pipe(map(res => (res)));
    }

    public saveServicePreferences(clientId, orgPreferenceEnum) {
        return this.http.post(ConfigService.config.userServiceUrl + '/saveOrganizationServicePreferences?CLIENT_ID=' + clientId, orgPreferenceEnum,
            this.getHeaders()).pipe(map(res => (res)));
    }

    public deleteOrganisation(payload) {
        return this.http.delete(ConfigService.config.userServiceUrl + '/organization/' + payload,
            this.getHeaders()).pipe(map((res: any) => res));
    }

    public getAllEntitlementDef() {
        return this.http.get<Set<EntitlementTo>>(ConfigService.config.userServiceUrl + '/entitlementDefs',
            this.getHeaders()).pipe(map((res => (res))));
    }

    setOrgsDataInModel(organizationsData) {
        const orgsData = [];
        organizationsData.forEach(element => {
            const eachOrg = new OrganizationTo(element);
            orgsData.push(eachOrg);
        });
        return orgsData;
    }

    public createOrganization(payload) {
        return this.http.post(ConfigService.config.userServiceUrl + '/organization/create', payload,
            this.getHeaders()).pipe(map((res: any) => res));
    }

    public updateOrganization(payload) {
        return this.http.put(ConfigService.config.userServiceUrl + '/organization/update', payload, this.getHeaders()).pipe(map((res: any) => res));
    }
}
