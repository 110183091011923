import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HomeService} from '../../home/home.service';
import {UsersService} from '../users.service';
import {SharedService} from '../../shared/shared.service';
import {Alerts} from '../../shared/enums/alerts';
import {Roles} from '../../shared/enums/roles';
import {UserBase} from "../UserBase";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserTo } from 'src/app/shared/models/userTo';
import {OrganizationTo} from "../../shared/models/organizationTo";

@Component({
    selector: 'app-select-roles',
    templateUrl: './select-roles.component.html',
    styleUrls: ['./select-roles.component.scss']
})
export class SelectRolesComponent extends UserBase implements OnInit {
    
    selectedOrgName: string;
    loggedInUser: UserTo;
    user: any = {};
    orgDialogRef: MatDialogRef<unknown, any>;

    constructor(@Inject(MAT_DIALOG_DATA) public selectedUsers,
                private usersService: UsersService, 
                public dialogRef: MatDialogRef<SelectRolesComponent>,
                private homeService: HomeService, 
                public sharedService: SharedService,
                private dialog: MatDialog) {
        super();
    }

    ngOnInit() {
        this.loggedInUser = new UserTo(this.homeService.userDetails);
        this.header = "Select Provisioned Users Roles";
    }

    isValidData() {
        this.markFormControlsAsTouched();
        return this.roles.valid;
    }

    createUsers() {
        if (this.isValidData()) {
            this.loading = true;
            //Automatically enable MFA for ssoUser
            // this.user.useMFA = this.user.ssoUser;
            this.user.useMFA  = false;

            this.selectedUsers.forEach(provisionedUser => {
                var userTo : any = {};
                var org: any = {}
                org.clientId = provisionedUser.psapId;
                userTo.userName = provisionedUser.userName;
                userTo.firstName = provisionedUser.firstName;
                userTo.email = provisionedUser.email;
                userTo.lastName = provisionedUser.lastName;
                userTo.phone = provisionedUser.phone;
                userTo.smsConsent = provisionedUser.smsConsent;
                userTo.org = org;
                userTo.roles = this.roles.getRawValue();
                this.usersService.createUser(userTo).subscribe(
                    data => {
                        this.loading = false;
                        this.sharedService.showAlert(Alerts.SUCCESS, 'User provisioned successfully');
                        this.dialogRef.close(true);
                    },
                    error => {
                        this.loading = false;
                        this.sharedService.handleErrors(error,
                            [{
                                "condition": (error.status === 400 && Object.keys(error.error).length > 0),
                                "msg": error.error[Object.keys(error.error)[0]]
                            }],
                            "Unable to provision user");
                    }
                );
            })
        }
    }

    resetUser() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.user = {};
                    this.roles.reset()
                }
            });
        } else {
            this.user = {};
            this.roles.reset();
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

}
