import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {OrganizationsService} from '../organizations.service';
import {SharedService} from '../../shared/shared.service';
import {Alerts} from '../../shared/enums/alerts';
import {OrganizationBase} from "../OrganizationBase";
import {ListParentOrganizationsComponent} from "../list-parent-organizations/list-parent-organizations.component";

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-create-organization',
    templateUrl: './create-organization.component.html',
    styleUrls: ['./create-organization.component.scss']
})
export class CreateOrganizationComponent extends OrganizationBase implements OnInit {
    
    orgDialogRef: MatDialogRef<unknown, any>;
    organization: any = {};
    defaultValue: string = "PSAP";

    constructor(public dialog: MatDialog, 
                public dialogRef: MatDialogRef<CreateOrganizationComponent>,
                public organizationsService: OrganizationsService, 
                public sharedService: SharedService) {
        super();
    }

    ngOnInit() {
        this.organization.type = this.defaultValue;
    }

    clear() {
        this.organization.parentClientId = undefined;
        this.parentOrgName = undefined;
    }

    selectParentOrg() {
        this.orgDialogRef = this.dialog.open(ListParentOrganizationsComponent, {
            data: this.organization.parentClientId 
        });
        this.orgDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.organization.parentClientId = result.clientId;
                this.parentOrgName = result.name;
            }
        });
    }

    createOrganization() {
        this.markFormControlsAsTouched();
        if (this.name.valid && this.clientId.valid) {
            this.loading = true;
            this.organizationsService.createOrganization(this.organization).subscribe(
                data => {
                    this.sharedService.showAlert(Alerts.SUCCESS, 'Organization created successfully');
                    this.loading = false;
                    this.dialogRef.close(true);
                },
                error => {
                    this.loading = false;
                    this.sharedService.handleErrors(error,
                                                    [{ "condition": (error.status === 400 && Object.keys(error.error).length > 0), 
                                                        "msg": error.error[Object.keys(error.error)[0]]
                                                    }], 
                                                    "Unable to create organization");
                }
            );
        }
    }

    resetOrganization() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Reset the values?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.organization = {};
                    this.organization.type = this.defaultValue;
                    this.parentOrgName = undefined;
                }
            });
        } else {
            this.organization = {};
            this.organization.type = this.defaultValue;
            this.parentOrgName = undefined;
        }
    }

    onValChange(val : string) {
        this.organization.type = val;
    }

    close() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Close the create Organization dialog box?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

    
}
