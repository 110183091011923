import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SearchTypeTo} from '../shared/models/searchTypeTo';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService} from '../../assets/config/config-svc.service';
import {HomeService} from '../home/home.service';
import {SharedService} from '../shared/shared.service';


@Injectable({
    providedIn: 'root'
})
export class SearchTypesService {
    updateSearchTypeList = new Subject<void>();
    updateSearchTypeList$ = this.updateSearchTypeList.asObservable();

    constructor(public http: HttpClient, public homeService: HomeService, public sharedService: SharedService) {
    }

    getHeaders() {
        return this.getHeadersForContent('application/json', 'json')
    }

    getHeadersForContent(contentType, responseType) {
        const httpOptions = {
            headers: new HttpHeaders({
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true,
            responseType: responseType
        };
        if (contentType) {
            httpOptions.headers.append('ContentType', contentType);
        }
        return httpOptions;
    }

    public createSearchType(payload) {
        return this.http.post(ConfigService.config.brokerServiceUrl + '/search-type/create', payload, this.getHeadersForContent(undefined, 'json')).pipe(map((res: any) => res));

    }

    public updateSearchType(payload) {
        return this.http.put(ConfigService.config.brokerServiceUrl + '/search-type/update', payload, this.getHeadersForContent(undefined, 'json')).pipe(map((searchType: any) => searchType));
    }

    public getSearchType(name): Observable<SearchTypeTo> {
        return this.http.get<SearchTypeTo>(ConfigService.config.brokerServiceUrl + '/search-type/read?SearchTypeName=' + name, this.getHeaders()).pipe(map((searchType: any) => searchType));
    }

    public getUITemplate(name) {
        return this.http.get(ConfigService.config.brokerServiceUrl + '/search-type/getUITemplate?SearchTypeName=' + name, this.getHeadersForContent(undefined, 'text')).pipe(map((res: any) => res));
    }

    public getAllSearchTypes(): Observable<SearchTypeTo[]> {
        return this.http.get<SearchTypeTo[]>(ConfigService.config.brokerServiceUrl + '/search-type/read-all',
            this.getHeaders()).pipe(map((searchTypes => this.setSearchTypesDataInModel(searchTypes))));
    }

    public fetchAvailableSearchTypes(): Observable<SearchTypeTo[]> {
        return this.http.get<SearchTypeTo[]>(ConfigService.config.brokerServiceUrl + '/available-providers', this.getHeaders())
                        .pipe(map((res) => this.setSearchTypesDataInModel(res)));
    }

    public deleteSearchType(name) {
        return this.http.delete(ConfigService.config.brokerServiceUrl + '/search-type/delete?SearchTypeName=' + name, this.getHeaders()).pipe(map((res: any) => res));
    }

    public downloadSearchTypeFile(name, type, filename): Observable<Blob> {
        return this.http.get<Blob>(ConfigService.config.documentServiceUrl + '/v1/document/get?path=SearchTypes/'+name+'/'+type+'/'+filename,
                            this.getHeadersForContent(undefined, 'blob')
                            );
      }

    setSearchTypesDataInModel(searchTypesData) {
        const searchTypeTo = [];
        if (searchTypesData) {
            searchTypesData.forEach(element => {
                const eachSearchType = new SearchTypeTo(element);
                searchTypeTo.push(eachSearchType);
            });
        }
        return searchTypeTo;
    }
}
