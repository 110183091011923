import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from '../../assets/config/config-svc.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SharedService} from '../shared/shared.service';
import {SearchSummary} from '../shared/models/searchSummary';
import {BrokerSearch} from '../shared/models/brokerSearch';
import {BrokerSearchRequestTo} from '../shared/models/BrokerSearchRequestTo';
import {Alerts} from '../shared/enums/alerts';

declare var SSE: any;

@Injectable({
    providedIn: 'root'
})

export class PowerSearchService {

    source: any;

    constructor(public http: HttpClient, 
                public sharedService: SharedService) {
    }

    ngOnDestroy() {
        if(this.source && this.source.readyState === this.source.OPEN) {
            this.source.close();
        }
    }

    getHeaders() {
        return this.getHeadersForContent('application/json', 'json')
    }

    getHeadersForContent(contentType, responseType) {
        const httpOptions = {
            headers: new HttpHeaders({
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true,
            responseType: responseType
        };
        if (contentType) {
            httpOptions.headers.append('ContentType', contentType);
        }
        return httpOptions;
    }

    emailReport(searchResultId, email, linkOnly) {
        const url = encodeURI(ConfigService.config.brokerServiceUrl + '/email-search-result'
            + '?uniqueId=' + searchResultId
            + '&email-addresses=' + email);
        return this.http.get(url, this.getHeaders());
    }

    doPowerSearch(payload: BrokerSearchRequestTo, callback) {
        let searchResults: Array<BrokerSearch> = [];
        const url = `${ConfigService.config.brokerServiceUrl}/search/authenticated-search`;
        this.source?.close();
        this.source = new SSE(url, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(this.sharedService.ID_TOKEN),
                    'Content-Type': 'application/json'
                },
            payload: JSON.stringify(payload),
            withCredentials: true}
        );
        this.source.addEventListener('EOD', e => {
            this.source.close();
            callback(searchResults);
        });
        this.source.addEventListener('error', e => {
            if (this.sharedService.isTokenExpired()) {
                this.source.close();
                this.sharedService.handleTokenExpiration(this.sharedService.tokenExpiryTitle, this.sharedService.tokenExpiryMsg);
            } else {
                this.source.close();
                callback(searchResults);
            }
        });
        this.source.addEventListener('message', e => {
            let result = JSON.parse(e["data"]);
            searchResults.push(new BrokerSearch().deserialize(result.data));
        });
        this.source.stream();
    }

    additionalDataSearch(searchId, resultIndex, callback) : any {
        const source = new SSE(
            `${ConfigService.config.brokerServiceUrl}/additional-data-search?SearchId=${searchId}&ResultIndex=${resultIndex}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(this.sharedService.ID_TOKEN),
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
        source.addEventListener("EOD", e => {
            source.close();
        });
        source.addEventListener("error", e => {
            if (this.sharedService.isTokenExpired()) {
                this.sharedService.handleTokenExpiration(this.sharedService.tokenExpiryTitle, this.sharedService.tokenExpiryMsg);
            }
            source.close();
        });
        source.addEventListener("message", e => { 
            let result = JSON.parse(e["data"]);
            callback(result.data);
        });
        source.stream();
        return source;
    }

    getSearchHistory(searchCriteria:any,orgId:string): Observable<any> {
        let searchhistoryurl = ConfigService.config.brokerServiceUrl + '/search-history';
        if (orgId) {
            searchhistoryurl = ConfigService.config.brokerServiceUrl + '/search-history?org_id=' + orgId;
        }
        return this.http.post<SearchSummary[]>(searchhistoryurl, searchCriteria,
            this.getHeaders());
    }

    getSearchHistoryCount(searchCriteria:any, orgId: string=''): Observable<any> {
        let searchhistoryurl = ConfigService.config.brokerServiceUrl + '/search-history/count';
        if (orgId) {
            searchhistoryurl = ConfigService.config.brokerServiceUrl + '/search-history/count?org_id=' + orgId;
        }
        return this.http.post<SearchSummary[]>(searchhistoryurl, searchCriteria, this.getHeaders());
    }

    searchResultId(searchId) {
        return this.http.get<BrokerSearch>(`${ConfigService.config.brokerServiceUrl}/search-for-id/${searchId}`, this.getHeaders());
    }

    sensitiveDataSearchForId(searchId) {
        return this.http.get<BrokerSearch>(`${ConfigService.config.brokerServiceUrl}/sensitive-data-search-for-id/${searchId}`, this.getHeaders());
    }

    searchSwlResults(securityWord, callback) : any {
        this.source?.close()
        this.source = new SSE(`${ConfigService.config.brokerServiceUrl}/retrieve-swl-data?SecurityWord=${securityWord}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(this.sharedService.ID_TOKEN)
            },
            withCredentials: true
        });
        this.source.addEventListener("message", e => {
            let result = JSON.parse(e["data"]);
            callback(result.data);
        });
        this.source.addEventListener("EOD", e => {
            this.source.close();
        });
        this.source.addEventListener('error', e => {
            let statusRegEx = /^[4-5]\d{2}$/
            if (this.sharedService.isTokenExpired()) {
                this.sharedService.handleTokenExpiration(this.sharedService.tokenExpiryTitle, this.sharedService.tokenExpiryMsg);
            } else if (e.source?.xhr?.status === 401) {
                this.sharedService.handleTokenExpiration(this.sharedService.tokenInvalidTitle, this.sharedService.tokenInvalidMsg);
            } else if (statusRegEx.test(e.source?.xhr?.status)) {
                this.sharedService.showAlertNoTimer(Alerts.ERROR, 'Unable to display Short Web Link');
            }
            this.source.close();
        });

        this.source.stream();
    }

    fetchFileData(fileUrl: string): Observable<Blob> {
        return this.http.get<Blob>(fileUrl, this.getHeadersForContent(undefined, 'blob'));
    }

}
