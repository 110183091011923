import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Alerts } from 'src/app/shared/enums/alerts';
import { PredefinedTextMessage } from 'src/app/shared/models/predefinedTextMessage'; 
import { SharedService } from 'src/app/shared/shared.service';
import { PredefinedTextMessageBase } from '../predefined-text-message-base';
import { PredefinedTextMessageService } from '../predefined-text-message.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Prefix } from 'src/app/shared/models/prefix';

@Component({
    selector: 'app-add-predefined-text-message',
    templateUrl: './add-predefined-text-message.component.html',
    styleUrls: ['./add-predefined-text-message.component.scss']
})
export class AddPredefinedTextMessageComponent extends PredefinedTextMessageBase implements OnInit {

    options: FormGroup;
    predefinedTextMessage: PredefinedTextMessage = new PredefinedTextMessage();
    loading: boolean = false;
    prefixList: Prefix[] = [];
    
    constructor(private fb: FormBuilder,
                private predefinedTextMessageService: PredefinedTextMessageService, 
                public dialogRef: MatDialogRef<AddPredefinedTextMessageComponent>,
                protected sharedService: SharedService) {
        super();
        this.options = this.fb.group({
            hideRequired: false,
            floatLabel: 'auto',
        });
    }

    ngOnInit() {
        this.getAllPrefix();
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }

    addPredefinedTextMessage() {
        if(this.isInValidData()) {
            return;
        }
        this.predefinedTextMessage.message = this.predefinedTextMessage.message.trim();
        this.loading = true;
        this.predefinedTextMessageService.addPredefinedTextMessage(this.predefinedTextMessage).subscribe(
            data => {
                this.loading = false;
                this.sharedService.showAlert(Alerts.SUCCESS, 'Predefined text message added successfully');
                this.dialogRef.close(true);
            },
            error => {
                this.loading = false;
                this.sharedService.handleErrors(error,
                    [
                        {"condition": (error.status == 400 && error.error), "msg": error.error}
                    ], "Failed to add text message");
            }
        );
    }

    
    getAllPrefix() {
        this.loading = true;
        this.predefinedTextMessageService.getAllPrefixes().subscribe(data => {
            this.prefixList = data;
            this.prefixList.sort((a,b) => a.description.toLocaleLowerCase() < b.description.toLocaleLowerCase() ? -1 : 1);
            this.loading = false;
        });
    }

    resetPredefinedTextMessage() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.predefinedTextMessage = new PredefinedTextMessage();
                    this.fieldcheck = false;
                }
            });
        } else {
            this.predefinedTextMessage = new PredefinedTextMessage();
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

}
