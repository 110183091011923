import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {OrganizationTo} from '../../shared/models/organizationTo';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getMatFormFieldMissingControlError } from '@angular/material/form-field';
import {HomeService} from '../../home/home.service';
import {SharedService} from '../../shared/shared.service';
import {Alerts} from '../../shared/enums/alerts';
import {OrganizationsService} from '../organizations.service';
import {Roles} from '../../shared/enums/roles';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrgPreference } from 'src/app/shared/models/orgPreference';
import { SearchTypeTo } from 'src/app/shared/models/searchTypeTo';
import { SearchTypesService } from 'src/app/search-types/search-type.service';
import { TextBlocksService } from 'src/app/text-blocks/text-blocks.service';
import { TextBlock } from 'src/app/shared/models/textBlock';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-organization-settings',
    templateUrl: './organization-settings.component.html',
    styleUrls: ['./organization-settings.component.scss']
})
export class OrganizationSettingsComponent implements OnInit {
    selectedOrg: OrganizationTo;
    resetData: OrgPreference[] = [];
    loading:boolean;
    userRoles = Roles;
    isDisabled:boolean = false;
    isChanged:boolean = false;
    pattern = /_/g;
    showSettings: boolean = false;
    header = "Organization Settings";
    title;
    options: UntypedFormGroup;
    orgSettingsFC: UntypedFormControl[] = [];
    readonly ORGSETTINGS = 'orgsettings';
    orgSettings: OrgPreference[] = [];
    searchTypeList: SearchTypeTo[] = [];
    textBlockList: TextBlock[] = [];
    selectedTextBlocks: string[] = [];
    allSettings: any;
    settingsToAdd = [];
    cpeIntegrationList = ['None', 'Power 911', 'Automatic Display'];

    constructor(@Inject(MAT_DIALOG_DATA) public data: OrganizationTo, 
                public sharedService: SharedService, 
                public dialogRef: MatDialogRef<OrganizationSettingsComponent>, 
                public orgService: OrganizationsService, 
                public homeService: HomeService,
                private searchTypesService: SearchTypesService,
                private textBlocksService: TextBlocksService,
                private fb: UntypedFormBuilder,
                private cdref: ChangeDetectorRef) {
        this.options = this.fb.group({
            hideRequired: false,
            floatLabel: 'auto'
        });
        this.title = data?.name;
    }

    ngOnInit() {
        this.loading = true;
        if (this.homeService.userDetails.roles.includes('SUPER')) {
            this.isDisabled = false;
        } else {
            this.isDisabled = true;
        }
        this.selectedOrg = this.data;
        this.getAllSearchTypes();
        this.getAllTextBlocks();
        this.getServicePreferencesForOrg();
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    getAllSearchTypes() {
        this.searchTypesService.getAllSearchTypes().subscribe(data => {
            this.searchTypeList = data;
        }, error => {
            this.dialogRef.close(false);
            this.sharedService.handleErrors(error, null, 'Unable to retrieve preferences for organization');
        });
    }

    getAllTextBlocks() {
        this.textBlocksService.getAllTextBlocks().subscribe(data => {
            this.textBlockList = data;
        }, error => {
            this.dialogRef.close(false);
            this.sharedService.handleErrors(error, null, 'Unable to retrieve preferences for organization');
        });
    }

    getErrorMessage(formControl, i) {
        if (formControl === this.ORGSETTINGS) {
            return this.orgSettingsFC[i].hasError('required') ? 'You must enter a value' : 'Enter a valid value ('+this.orgSettings[i].regex+')';
        } 
    }

    getServicePreferencesForOrg() {
        this.orgService.getServicePreferencesForOrg(this.selectedOrg.clientId).subscribe( orgData => {            
            var orgsettings = JSON.parse(JSON.stringify(orgData));
            this.orgService.getAllOrganizationsSettings(this.selectedOrg.clientId).subscribe( allOrgData => {
                this.allSettings = JSON.parse(JSON.stringify(allOrgData));
                this.allSettings.sort((a, b) => a.name < b.name ? -1 : 1);             
                Object.keys(orgsettings).forEach(element => {
                    this.allSettings.forEach( allElement => {
                        if(element === allElement.name) {
                            this.resetData.push(new OrgPreference({
                                name: allElement.name,
                                displayName: allElement.displayName,
                                description: allElement.description,
                                regex: allElement.regex,                                
                                value: this.getPreferenceValue(allElement.name, orgsettings[allElement.name]),
                                datatype: allElement.datatype  
                                
                            }));
                        }
                    });
                });
                this.resetData.sort((a, b) => a.name < b.name ? -1 : 1);
                this.resetData.forEach( element => {
                        this.orgSettings.push(new OrgPreference(element));
                        this.orgSettingsFC.push(this.getFormControl(element));
                });
                this.loading = false;
            }, error => {
                this.dialogRef.close(false);
                this.sharedService.handleErrors(error, null, 'Unable to retrieve preferences for organization');
            });
        }, error => {
            this.dialogRef.close(false);
            this.sharedService.showConfirmAlert(Alerts.ERROR, 'User does not have access to the preferences for organization');
        });
        
    }

    getPreferenceValue(preferenceName:string, value:any) {
        if(preferenceName === "TEXT_BLOCKS") {
            this.selectedTextBlocks = [];
            value.split(",").forEach((textBlockName: string) => {
                if(textBlockName.length > 0) {
                    this.selectedTextBlocks.push(textBlockName);
                }
            });
            return JSON.parse(JSON.stringify(this.selectedTextBlocks));
        } 
        return value;
    }

    settingsNotDefined(defaultSetting) {
        var notdefined = true
        this.orgSettings.forEach(element => {
            if (defaultSetting.name==element.name) {
                notdefined = false;
            }
        });
        return notdefined;
    }

    changeSettings(value, setting) {
        if (value) {
            this.settingsToAdd.push(setting);
        } else {
            const index = this.settingsToAdd.findIndex(x => x.name === setting.name);
            if (index >= 0) {
                this.settingsToAdd.splice(index, 1);
            }
        }
    }

    addNewSettings() {
        if(this.settingsToAdd.length!=0) {
            this.settingsToAdd.forEach( element => {
                this.orgSettings.push(
                    new OrgPreference({
                        name: element.name,
                        displayName: element.displayName,
                        description: element.description,
                        regex: element.regex,
                        value: element.value,
                        datatype: element.datatype
                    })
                );
            });
            this.orgSettings.sort((a, b) => a.name < b.name ? -1 : 1);        
            this.orgSettingsFC = [];
            this.orgSettings.forEach( element => {                
                    this.orgSettingsFC.push(this.getFormControl(element));                                
            });
        }
        this.revertNewSettings();
    }

    revertNewSettings(){
        this.showSettings = false; 
        this.settingsToAdd=[];
    }

    deleteSetting(setting) {
        this.change();
        const index = this.orgSettings.findIndex(x => x.name === setting.name);
        if (index >= 0) {
            this.orgSettings.splice(index, 1);
            this.orgSettingsFC.splice(index, 1);
        }
    }

    updateSettings() {
        this.markFormControlsAsTouched();
        let valid : Boolean = true;
        this.orgSettingsFC.forEach((element, id) => {
            if (!element.valid) {
                valid = false;
            }            
        });
        
        if (valid) {
            this.loading = true;
            var updated = false;
            if(this.resetData.length != this.orgSettings.length) {
                updated = true;
            } else {
                this.resetData.forEach( resetElement => {
                    this.orgSettings.forEach( updatedElement => {
                        if(resetElement.name===updatedElement.name && resetElement.value!==updatedElement.value) {
                            updated = true;
                        }
                    });
                });
            }

            if (updated) {
                var updatedSettings = {};
                this.orgSettings.forEach( updatedElement => {
                    if(updatedElement.name === "TEXT_BLOCKS") {
                        let textBlocks:string = "";
                        this.selectedTextBlocks.forEach((textBlock:string) => {
                            textBlocks += "," + textBlock;
                        });
                        if(textBlocks.length > 0) {
                            textBlocks += ',';
                        }
                        updatedSettings[updatedElement.name] = textBlocks;
                    } else {
                        updatedSettings[updatedElement.name] = updatedElement.value;
                    }
                });
                  this.orgService.saveServicePreferences(this.selectedOrg.clientId, updatedSettings).subscribe(data => {
                    this.loading = false;
                    this.sharedService.showAlert(Alerts.SUCCESS, 'Organization Settings Updated successfully');
                    this.dialogRef.close(true);
                }, error => {
                    this.loading = false;
                    this.sharedService.handleErrors(error, null, error.error, true);
                });
            } else {
                this.loading = false;
                this.sharedService.showAlert(Alerts.ERROR, 'No modifications were made');
            }
        }
    }

    markFormControlsAsTouched() {
        this.orgSettings.forEach((element, i) => {
            this.orgSettingsFC[i].markAsTouched(); 
        });
    }

    getDescription(setting) {
        return setting.description + ' (Pattern: ' + setting.regex + ')';
    }

    close() {
        if (this.isChanged) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result?.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

    reset() {
        if (this.isChanged) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result?.value) {
                    this.orgSettingsFC = [];
                    this.orgSettings = [];
                    this.resetData.forEach( element => {
                        this.orgSettings.push(new OrgPreference(element));                        
                        this.orgSettingsFC.push(this.getFormControl(element));
                    });
                    this.isChanged = false;
                }
            });
        } else {
            this.orgSettingsFC = [];
            this.orgSettings = [];
            this.resetData.forEach( element => {
                this.orgSettings.push(new OrgPreference(element));
                this.orgSettingsFC.push(this.getFormControl(element));
            });
        }
    }

    change() {
        if (!this.isChanged) {
            this.isChanged = true;
        }
    }

    getFormControl(orgPref: OrgPreference) {
        if(orgPref.datatype === 'checkbox'){
            return new UntypedFormControl('', [Validators.required]);
        }else{
            return new UntypedFormControl('')
        }
    }

    onTextBlockChange(selected: boolean, textBlock: TextBlock) {
        const index = this.selectedTextBlocks.findIndex(x => x === textBlock.name);
        if(selected && index === -1) {
            this.selectedTextBlocks.push(textBlock.name);
        } else if(!selected && index >= 0) {
            this.selectedTextBlocks.splice(index, 1);
        }
    }

    displayTextBlockName(name: string) {
        let textBlockName = JSON.parse(JSON.stringify(name));
        this.selectedTextBlocks.forEach((value, index)=>{
            if(value === name) {
                textBlockName += " - (" + (index+1) +")"; 
            }
        });
        return textBlockName;
    }

}
