import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from '../../assets/config/config-svc.service';
import {map} from 'rxjs/operators';
import { SharedService } from '../shared/shared.service';

const url = '/version'

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(public http: HttpClient, public sharedService: SharedService) {
    }

    getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'transactionId': this.sharedService.generateUUID(),
                'reportType': 'All'
            }),
            withCredentials: true
        };
        return httpOptions;
    }

    public searchForOrg(searchTerm: string) {
        return this.http.get<any>(ConfigService.config.userServiceUrl + '/organizations?SEARCH_TERM=' + searchTerm,
            this.getHeaders()).pipe(map(res => (res)));
    }

}
