import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrganizationTo } from 'src/app/shared/models/organizationTo';
import { SharedService } from '../../shared/shared.service';
import { ClientCredentialsTo } from 'src/app/shared/models/clientCredentialsTo';
import { ProviderTo } from 'src/app/shared/models/providerTo';
import { ProvidersService } from 'src/app/providers/provider.service';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-client-credentials',
    templateUrl: './client-credentials.component.html',
    styleUrls: ['./client-credentials.component.scss']
})
export class ClientCredentialsComponent implements OnInit {

    loading: boolean;
    showListBlock: boolean = true;
    showEditBlock: boolean = false;
    isProviderAlreadyExist: boolean = false;
    selectedOrg: OrganizationTo;
    clientCredentials: ClientCredentialsTo;
    clientCredentialsList: Array<ClientCredentialsTo> = [];
    providerList: Array<ProviderTo> = [];
    displayedColumns: string[] = ['provider', 'clientId', 'options'];
    selection = new SelectionModel<ClientCredentialsTo>(true, []);
    dataSource = new MatTableDataSource<ClientCredentialsTo>(this.clientCredentialsList);
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    paginatorLength: number;
    count: number = 1;
    fieldcheck: boolean = false;

    clientId = new UntypedFormControl('', [Validators.required]);
    secret = new UntypedFormControl('', [Validators.required]);
    provider = new UntypedFormControl('', [Validators.required]);

    readonly CLIENT_ID: string = 'clientId';
    readonly CLIENT_SECRET: string = 'secret';
    readonly PROVIDER: string = 'provider';

    constructor(@Inject(MAT_DIALOG_DATA) public data: OrganizationTo,
                public sharedService: SharedService, 
                public dialogRef: MatDialogRef<ClientCredentialsComponent>,
                private providersService: ProvidersService,
                private cdref: ChangeDetectorRef) { 
        this.selectedOrg = data;
    }

    ngOnInit() {
        this.loading = true;
        this.getProviderList();
        this.getClientCredentialsList(this.selectedOrg.clientId);
    }

    getProviderList(): void {
        //Change below call to new broker-svc call to fetch only those providers for which org has entitlement enabled
        this.providersService.getAllProviders().subscribe(data => {
            this.providerList = data;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    getClientCredentialsList(orgId: string): void {
        let sampleData = {
            "id": "00000",
            "orgId" : "3031112222",
            "clientId" : "070707070",
            "secret" : "jhsfyhg623hsujhsdfs345389hshdfs==",
            "provider" : "Alarm"
        };
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.clientCredentialsList.push(new ClientCredentialsTo(sampleData));
        this.loading = false;
        //Add new broker-svc call to fecth all client credentials for given org.
        this.setclientCredentialsListData();
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    setclientCredentialsListData() {
        if (this.clientCredentialsList.length > 20) {
            this.paginatorLength = this.clientCredentialsList.length;
        } else {
            this.paginatorLength = 20;
        }
        const currentfilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<ClientCredentialsTo>(this.clientCredentialsList);
        this.dataSource.filter = currentfilter;
        this.dataSource.filterPredicate = (data: ClientCredentialsTo, filter: string) =>
            (data.clientId && data.clientId.trim().toLowerCase().indexOf(filter) !== -1) ||
            (data.secret && data.secret.trim().toLowerCase().indexOf(filter) !== -1) ||
            (data.provider && data.provider.trim().toLowerCase().indexOf(filter) !== -1);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        this.dataSource.sortingDataAccessor = (data: ClientCredentialsTo, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }
            return data[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
        this.loading = false;
    }

    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }

    change(event: any): void {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }

    getErrorMessage(formControl: string): string {
        if (formControl === this.CLIENT_ID) {
            return this.clientId.hasError('required') ? 'You must enter a value' :  '';
        } else if (formControl === this.CLIENT_SECRET) {
            return this.secret.hasError('required') ? 'You must enter a value' :  '';
        } else if (formControl === this.PROVIDER) {
            return this.provider.hasError('required') ? 'You must select a provider ' :  '';
        }
    }

    markFCAsTouched(): void {
        this.clientId.markAsTouched();
        this.secret.markAsTouched();
        this.provider.markAsTouched();
    }

    markFCAsUnTouched(): void {
        this.clientId.markAsUntouched();
        this.secret.markAsUntouched();
        this.provider.markAsUntouched();
    }

    addCredentials(): void {
        this.markFCAsUnTouched();
        this.showListBlock = false;
        this.showEditBlock = false;
        this.clientCredentials = new ClientCredentialsTo();
    }

    editCredentials(object: ClientCredentialsTo): void {
        this.markFCAsUnTouched();
        this.showListBlock = false;
        this.showEditBlock = true;
        this.clientCredentials = new ClientCredentialsTo(object);
    }

    updateCredentials(): void {
        this.markFCAsTouched();
        if(this.clientId.valid && this.secret.valid && this.provider.valid && !this.providerAlreadyExist()) {
            //Add new broker-svc call to add or update credentials
        }
    }

    providerAlreadyExist(): boolean {
        for(let credential of this.clientCredentialsList) {
            if(credential.provider === this.clientCredentials.provider && credential.id !== this.clientCredentials.id) {
                this.isProviderAlreadyExist = true;
                return this.isProviderAlreadyExist;
            }
        }
        this.isProviderAlreadyExist = false;
        return this.isProviderAlreadyExist;
    }

    deleteCredentials(object: ClientCredentialsTo): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete the client credentials for Provider "' + object.provider + '"',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            showLoaderOnConfirm: true
        }).then((result:any) => {
            if (result.value) {
                //Add new broker-svc call to delete credentials
                this.getClientCredentialsList(this.selectedOrg.clientId);
            }
        });
    }

    close(): void {
        this.dialogRef.close(false);
    }

    closeAddOrEditBlock(): void {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.backToList();
                }
            });
        } else {
            this.backToList();
        }
    }

    backToList(): void {
        this.showListBlock = true;
        this.showEditBlock = false;
        this.clientCredentials = new ClientCredentialsTo();
        this.setclientCredentialsListData();
    }
}
