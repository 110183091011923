import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import { View } from './view';
import {ActivatedRoute, Router} from '@angular/router';
import { NavigationService} from '../navigation/navigation.service';
import {SharedService} from '../shared/shared.service';
import { Roles } from '../shared/enums/roles';
import { interval, Subscription } from 'rxjs';
import { Alerts } from '../shared/enums/alerts';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {LoginService} from '../login/login.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    version: string = require('../../../package.json').version;
    view: View = new View();
    fromSettings: boolean = false;
    isShortWebLink: boolean = false;
    token: string;
    refreshToken: string;

    showTokenExpiry: boolean = false;
    tokenExpiryTime: number;
    subscription: Subscription;
    milliSecondsInASecond: number = 1000;
    hoursInADay: number = 24;
    minutesInAnHour: number = 60;
    SecondsInAMinute: number  = 60;

    secondsToExpire: number;
    minutesToExpire: number;
    hoursToExpire: number;
    redirectUri: string;

    constructor(public homeService: HomeService, public router: Router,
                private route: ActivatedRoute, private sharedService: SharedService,
                public navigationService: NavigationService,
                private loginService: LoginService) {
        this.homeService.navigate.subscribe(
            data => {
                if (data) {
                    if (data === "loginUser") {
                        this.fromSettings = true
                    }
                    this.view.viewType = data;
                } else
                    this.view.viewType = 'powerSearch';
            }
        );
    }

    ngOnInit() {
        this.sharedService.redirectUri$.subscribe((redirectUri: string) => {
            this.redirectUri = redirectUri;
        });
        this.isShortWebLink = this.route.snapshot.url[0]?.toString() === 'shortweblink';
        this.token = this.route.snapshot.queryParamMap.get("token");
        if(this.route.snapshot.queryParamMap?.has("refresh-token")) {
            this.refreshToken = this.route.snapshot.queryParamMap.get("refresh-token");
        }
        let fragment: string = this.route.snapshot.fragment;
        if (this.token != null) {
            localStorage.setItem(this.sharedService.ID_TOKEN, this.token);
        } else {
            var token = localStorage.getItem(this.sharedService.ID_TOKEN);
            if(token && token !== 'null') {
                this.token = token;                           
            } else {                            
                var error = this.route.snapshot.queryParamMap.get("error");
                if(error != null) {                
                    this.router.navigate(['session/login'], {queryParams: { error: error }});
                } else {                
                    this.router.navigate(['session/login']);
                }
                return;
            }
        }

        if (this.refreshToken != null) {
            localStorage.setItem(this.sharedService.REFRESH_TOKEN, this.refreshToken);
        } else {
            var refreshToken = localStorage.getItem(this.sharedService.REFRESH_TOKEN);
            if (refreshToken && refreshToken !== 'null') {
                this.refreshToken = refreshToken;                           
            }
        }

        this.router.navigate([], {
            queryParams: {
                'token': null,
                'refresh-token': null
            },
            fragment: fragment,
            queryParamsHandling: 'merge'
        });
        this.checkTokenExpired();
        this.homeService.getUserInfo().subscribe(data => {
            this.sharedService.setLoggedInUser(data);
            if (this.isShortWebLink) {
                this.view.viewType = 'searchResults';
            } else if (data.roles.includes(Roles.SEARCH) || data.roles.includes(Roles.SUPER)) {
                this.view.viewType = 'powerSearch';
            } else if (data.roles.includes(Roles.VIEW) || data.roles.includes(Roles.ADMIN)) {
                this.view.viewType = 'view';
            } else {
                this.view.viewType = 'loginUser';
            }
        });
    }

    ngOnDestroy() {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    checkTokenExpired() {
        const jwt = localStorage.getItem(this.sharedService.ID_TOKEN)?.split('.');
        this.tokenExpiryTime = 1000 * (JSON.parse(atob(jwt[1])).exp || 0);
        if ((jwt?.length > 1) && this.tokenExpiryTime > Date.now()) {
            this.subscription = interval(1000)
                .subscribe(x => { 
                    let timeDifference = this.tokenExpiryTime - new  Date().getTime();
                    this.allocateTimeUnits(timeDifference);
                });
        } else {
            localStorage.setItem(this.sharedService.ID_TOKEN, null);
            localStorage.setItem(this.sharedService.REFRESH_TOKEN, null);
            this.loginService.signOutAndRedirect(this.redirectUri);
        }
    }

    allocateTimeUnits (timeDifference: number) {
        if (timeDifference <= 0) {
            localStorage.setItem(this.sharedService.ID_TOKEN, null);
            localStorage.setItem(this.sharedService.REFRESH_TOKEN, null);
            this.loginService.signOutAndRedirect(this.redirectUri);
        }
        this.secondsToExpire = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
        this.minutesToExpire = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
        this.hoursToExpire = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
        
        if(this.hoursToExpire === 0 && this.minutesToExpire < 30) {
            this.showTokenExpiry = true;
        }
    }

    refreshAccessToken() {
        if (this.refreshToken === null) {
            this.refreshToken = localStorage.getItem(this.sharedService.REFRESH_TOKEN);
        }
        if (this.refreshToken === null) {
            this.sharedService.showAlert(Alerts.ERROR, "Refresh token is missing. Please make a new SignIn request");
        } else {
            Swal.fire({
                title: 'Please Wait...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            this.homeService.refreshAccessToken({refreshToken:this.refreshToken}).subscribe(data => {
                Swal.close();
                this.token = data.accessToken;
                this.refreshToken = data.refreshToken;
                localStorage.setItem(this.sharedService.ID_TOKEN, this.token);
                localStorage.setItem(this.sharedService.REFRESH_TOKEN, this.refreshToken);
                const jwt = this.token.split('.');
                this.tokenExpiryTime = 1000 * (JSON.parse(atob(jwt[1])).exp || 0);
                this.sharedService.showAlert(Alerts.SUCCESS, "Your session has been extended successfully");
                this.showTokenExpiry = false;
            },
            error => {
                Swal.close();
                this.sharedService.showAlert(Alerts.ERROR, error.error.message);
            });
        }
    }

    displayExpiryTime() {
        let timeString: string = "";
        if(this.hoursToExpire > 0) {
            let hourString = this.hoursToExpire.toString(); 
            timeString += (hourString.length === 1 ? "0"+hourString : hourString) + ":";
        }
        let minutesString = this.minutesToExpire.toString(); 
        timeString += (minutesString.length === 1 ? "0"+minutesString : minutesString) + ":";

        let secondsString = this.secondsToExpire.toString(); 
        timeString += secondsString.length === 1 ? "0"+secondsString : secondsString;
        
        return timeString;
    }
}
