import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {PowerSearchService} from '../../power-search/power-search.service';
import {SharedService} from '../../shared/shared.service';
import {SearchSummary} from '../../shared/models/searchSummary';
import {CivicAddressTo} from '../../shared/models/CivicAddressTo';
import {AdvancedSearchDialog} from './search-history-advanced-search.component';
import * as moment from 'moment';
import { BrokerSearch } from 'src/app/shared/models/brokerSearch';
import { HomeService } from 'src/app/home/home.service';
import { SearchTypesService } from 'src/app/search-types/search-type.service';

@Component({
    selector: 'app-search-history',
    templateUrl: './search-history.component.html',
    styleUrls: ['./search-history.component.scss']
})
export class SearchHistoryComponent implements OnInit {
    searchTypes: Array<string> = [];
    isLoading:boolean = false;
    displayedColumns: string[] = ['searchType', 'provider',  'searchRequest', 'timestamp', 'incidentId', 'userId'];
    dataSource = new MatTableDataSource;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    searchHistoryCriteria: any;
    searchText:string;
    orgId: string = "";
    resultErrorMsg:string = "No records found";

    constructor(public powerSearchService: PowerSearchService, 
                private cdref: ChangeDetectorRef, 
                public sharedService: SharedService,
                private dialog: MatDialog,
                private homeService: HomeService,
                private searchTypesService : SearchTypesService) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.sharedService.currentOrgId$.subscribe(data => {
            if(this.orgId !== data?.orgId && data.navigate === 'searchHistory') {
                if (data?.orgId) {
                    this.orgId = data.orgId;
                    this.fetchAvailableSearchTypes();
                } else {
                    this.fetchAvailableSearchTypes();
                }
            }
        });
        
    }

    fetchAvailableSearchTypes(): void {
        this.searchTypes = [];
        this.searchTypesService.fetchAvailableSearchTypes().subscribe(data => {
            data.forEach(searchType =>{
                this.searchTypes.push(searchType.name);
            })
            this.searchTypes.sort();
            this.loadSearchHistory();
        }, error => {
            this.isLoading = false;
            this.sharedService.handleErrors(error);
        });
    }

    loadSearchHistory(): void {
        this.searchHistoryCriteria = this.sharedService.getSearchHistoryCriteria();
        if(!this.searchHistoryCriteria) {
            this.searchHistoryCriteria = {
                allUser: false, 
                fromDateTime: moment().subtract(24,"hours").toISOString(), 
                includeSearchesWithoutResults: false,
                searchTypes: JSON.parse(JSON.stringify(this.searchTypes))
            };
        }
        
        if(this.sharedService.getSearchHistoryData().length > 0) {
            this.setDataSource(this.sharedService.getSearchHistoryData());
            this.isLoading = false;
        } else {
            this.doSearchHistory(this.searchHistoryCriteria);
        }
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    handleError(error:any): void {
        this.isLoading = false;
        this.openfilterDialog();
        this.sharedService.handleErrors(error,
                                        [{ "condition": (error.status === 400 && error.error), 
                                            "msg": error.error
                                        }], 
                                        "Unable to fetch search history");
    }

    doSearchHistory(searchHistoryCriteria:any): void {
        this.isLoading = true;
        this.sharedService.setSearchHistoryCriteria(searchHistoryCriteria);
        let criteria = JSON.parse(JSON.stringify(searchHistoryCriteria));
        if(criteria.searchTypes && criteria.searchTypes.length === this.searchTypes.length) {
            delete criteria["searchTypes"];
        }
        this.getSearchHistoryCount(criteria);
    }

    getSearchHistoryCount(criteria:any): void {
        this.powerSearchService.getSearchHistoryCount(criteria, this.orgId).subscribe(
            data => {
                if(data === 0 || data > 100) {
                    this.resultErrorMsg = data === 0 ? "No records found" :
                                                        "There are more than 100 records found. Please restrict your search criteria.";
                    let searchHistoryData: Array<SearchSummary> = [];
                    this.sharedService.setSearchHistoryData(searchHistoryData);
                    this.setDataSource(searchHistoryData);
                    this.isLoading = false

                } else {
                    this.getSearchHistoryData(criteria,this.orgId);
                }
            },
            error => {
                this.handleError(error);
                this.isLoading = false;
            }
        );
    }

    getSearchHistoryData(criteria:any,orgID:string): void {
        this.powerSearchService.getSearchHistory(criteria,orgID).subscribe(
            data => {
                this.sharedService.setSearchHistoryData(data);
                this.setDataSource(data);
                this.isLoading = false;
                if (this.searchText) {
                    this.dataSource.filter = this.searchText.trim().toLowerCase();
                }
            },
            error => {
                this.handleError(error);
                this.isLoading = false;
            }
        );
    }

    openfilterDialog(): void {
        const dialogRef = this.dialog.open(AdvancedSearchDialog, {
            panelClass: 'mat-dialog-content',
            data: {searchTypes: this.searchTypes,
                   searchCriteria: this.searchHistoryCriteria}
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.doSearchHistory(result);
            }
        });
    }

    setDataSource(data: SearchSummary[]) {
        this.dataSource = new MatTableDataSource<any>(data);
        setTimeout(() => this.dataSource.sort = this.sort);
        this.dataSource.filterPredicate = (d: any, filter: string) =>
            (d.searchType && d.searchType.toLowerCase().indexOf(filter) !== -1) ||
            (d.provider && d.provider.toLowerCase().indexOf(filter) !== -1) ||
            (d.searchRequest && this.formatSearchRequest(d).toLowerCase().indexOf(filter) !== -1) ||
            (d.timestamp && this.formatTimestamp(d).indexOf(filter) !== -1) ||
            (d.incidentId && d.incidentId.toLowerCase().indexOf(filter) !== -1) ||
            (d.userId && d.userId.toLowerCase().indexOf(filter) !== -1);
        this.dataSource.sortingDataAccessor = (searchData: any, sortHeaderId: string) => {
            if (sortHeaderId.toLowerCase() === "timestamp") {
                return new Date(searchData[sortHeaderId]);
            }
            if (sortHeaderId === "searchRequest") {
                return this.formatSearchRequest(searchData).toLowerCase();
            }
            if (typeof searchData[sortHeaderId] === 'string') {
                return searchData[sortHeaderId].toLocaleLowerCase();
            }
            return searchData[sortHeaderId];
        };
    }

    applyFilter(filterValue: string) {
        this.searchText = filterValue;
        this.dataSource.filter = filterValue?.trim().toLowerCase();
    }

    currentSearch(element: SearchSummary) {
        var searchResults = [];
        var event = new SearchSummary().deserialize(element);
        this.powerSearchService.searchResultId(event.uniqueSearchId).subscribe(data => {
            searchResults.push(new BrokerSearch().deserialize(data));
            this.sharedService.setSearchResults(searchResults);
            this.homeService.navigate.next('searchResults');
        }, error => {
            this.sharedService.handleErrors(error);
        });
    }

    formatTimestamp(timestamp: any) {
        return new Date(timestamp).toLocaleString('en-US', {"hour12": false }).replace(',','');
    }

    formatSearchRequest(element: SearchSummary) {
        let param: string = "";
        if(element.searchRequest.phone) {
            param += element.searchRequest.phone;
        }
        if(element.searchRequest.location) {
            param += param.length > 0 ?  " | " : "";
            param += element.searchRequest.location.latitude + "," + element.searchRequest.location.longitude;
        }
        if(element.searchRequest.address) {
            param += param.length > 0 ?  " | " : "";
            param += new CivicAddressTo().deserialize(element.searchRequest.address).format();
        }
        if (element.searchRequest.vehicleSearchCriteria) {
            param += param.length > 0 ?  " | " : "";
            if (element.searchRequest.vehicleSearchCriteria.tag) {
                param += element.searchRequest.vehicleSearchCriteria.tag;
            }
            if (element.searchRequest.vehicleSearchCriteria.tagState) {
                param += ", " + element.searchRequest.vehicleSearchCriteria.tagState;
            }
            if (element.searchRequest.vehicleSearchCriteria.vin) {
                param += element.searchRequest.vehicleSearchCriteria.vin;
            }
        }
        return param.trim().length > 0 ? param.trim() : "-NA-";
    }
    
}
