import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {UserTo} from '../../shared/models/userTo';
import {HomeService} from '../../home/home.service';
import {UsersService} from '../users.service';
import {OrganizationTo} from '../../shared/models/organizationTo';
import {SharedService} from '../../shared/shared.service';
import {AngularCsv} from 'angular7-csv/dist/Angular-csv'

import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
    ListParentOrganizationsComponent
} from "../../organizations/list-parent-organizations/list-parent-organizations.component";

@Component({
    selector: 'app-bulk-upload',
    templateUrl: './bulk-upload.component.html',
    styleUrls: ['./bulk-upload.component.scss']
})

export class BulkUploadComponent implements OnInit {
    header = "Bulk Upload"
    title
    user: UserTo;
    org: OrganizationTo;
    payload = {
        'userName': '',
        'email': '',
        'firstName': '',
        'lastName': ''
    };
    roles: string[] = ['SEARCH'];
    isFileAdded = false;
    isLoading = false;
    isError = false;
    isStatus = true;
    fileName: string;
    files;
    noFile = false;
    statusMsg = [];
    noFileStatus;
    csv: string;
    totalLines;
    emptyFile = false;
    wrongFormat = false;
    superUser;
    dataSource = new MatTableDataSource<Object>(this.statusMsg);
    displayedColumns: string[] = ['userName', 'status', 'comment'];
    loggedinUser = this.homeService.userDetails;
    successCount = [];
    failureCount = [];
    check = [];
    csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        title: '',
        useBom: false,
        noDownload: false,
        headers: ["First Name", "Last Name", "Email", "Username","Create SSO User", "Phone"]
    };
    sampleData: any;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    selectedOrgName: string;
    orgDialogRef;


    constructor(@Inject(MAT_DIALOG_DATA) public selectedOrg: string, private zone: NgZone,
                private dialogRef: MatDialogRef<BulkUploadComponent>, private homeService: HomeService,
                private usersService: UsersService,
                public sharedService: SharedService,
                private dialog: MatDialog) {
        this.user = new UserTo(this.payload);
    }

    ngOnInit() {
        this.sampleData = [ ["Jane", "Doe", "jdoe@example.com", "janedoe", "true", "2112223333"], ["John", "Smith", "jsmith@example.com", "jsmith", "false", "2112223333"] ];
        this.superUser = this.loggedinUser.roles.includes('SUPER');
        if (this.superUser && this.selectedOrg) {
            this.org = new OrganizationTo(this.selectedOrg);
        } else {
            this.org = JSON.parse(JSON.stringify(this.loggedinUser.org));
        }
        this.selectedOrgName = this.org.name;
    }

    selectOrg() {
        this.orgDialogRef = this.dialog.open(ListParentOrganizationsComponent, {
            data: this.org.clientId
        });
        this.orgDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.org = result;
                this.selectedOrgName = result.name;
            }
        });
    }

    onFilesAdded(files) {
        if (files && files.length > 0) {
            this.fileName = files[0].name;
            this.files = files;
        }
    }

    downloadFiles() {
        new AngularCsv(this.sampleData, "Sample-File", this.csvOptions);
    }

    uploadFiles() {
        if (this.files) {
            Swal.fire({
                text: 'Do you want to upload users for organization "' + this.org.name + '"?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.value) {
                    this.isFileAdded = true;
                    this.isLoading = true;
                    const file: File = this.files.item(0);
                    const reader: FileReader = new FileReader();
                    reader.readAsText(file);
                    reader.onload = (e) => {
                        this.csv = reader.result as string;
                        this.createUserData();
                    };
                }
            });
        } else {
            this.noFile = true;
        }
    }

    createUserData() {
        const lines = this.csv.match(/[^\r\n]*/g);
        const newLines = lines.filter((line) => {
            return line !== ',,,';
        });
        this.totalLines = newLines.filter((line) => {
            return line !== '';
        });
        if (this.totalLines.length !== 0) {
            this.check = this.totalLines[0].split(',');
        }
        if (this.totalLines.length > 1) {
            if (this.check[0] === "First Name" && this.check[1] === "Last Name" && this.check[2] === "Email" && this.check[3] === "Username" && this.check[5] === "Phone") {
                for (let i = 1; i < this.totalLines.length; i++) {
                    let line = [];
                    line = this.totalLines[i].split(',');
                    this.user.firstName = line[0].trim();
                    this.user.lastName = line[1].trim();
                    this.user.email = line[2].trim();
                    this.user.userName = line[3].trim();
                    if(typeof line[4] != 'undefined' && line[4]) {
                        this.user.ssoUser = line[4];
                    } else {
                        this.user.ssoUser = true;
                    }                
                    this.user.roles = this.roles;
                    this.user.org = this.org;
                    if(line[5]!=null) {
                        this.user.phone = line[5].trim();
                    }
                    this.createUser(this.user);
                }
            } else {
                this.isLoading = false;
                this.isStatus = false;
                this.wrongFormat = true;
                this.noFileStatus = 'Invalid File Format, Please check the given sample file format and upload.';
            }
        } else if (this.totalLines.length === 1 && (this.check[0] !== "First Name" || this.check[1] !== "Last Name" || this.check[2] !== "Email" || this.check[3] !== "Username")) {
            this.isLoading = false;
            this.isStatus = false;
            this.wrongFormat = true;
            this.noFileStatus = 'Invalid File Format, Please check the given sample file format and upload.';
        } else {
            this.isLoading = false;
            this.isStatus = false;
            this.emptyFile = true;
            this.noFileStatus = 'Please check the uploaded file, No Users to Create';
        }
    }

    createUser(user:UserTo) {
        const status: Object = {
            'userName': '',
            'status': '',
            'comment': '',
        };
        status['userName'] = user.userName;
        if (user.firstName.length < 1 || user.firstName.length > 50) {
            status['status'] = "Failure";
            status['comment'] = "First name must be between 1 and 50 characters";
            this.recordResponse(status);
        } else if (user.lastName.length < 1 || user.lastName.length > 50) {
            status['status'] = "Failure";
            status['comment'] = "Last name must be between 1 and 50 characters";
            this.recordResponse(status);
        } else if (user.userName.length < 1 || user.userName.length > 50) {
            status['status'] = "Failure";
            status['comment'] = "Username must be between 1 and 50 characters";
            this.recordResponse(status);
        } else if (user.phone.length !== 10) {
            status['status'] = "Failure";
            status['comment'] = "phone must be 10 characters";
            this.recordResponse(status);
        } else {
            this.usersService.createUser(this.user).subscribe(
                data => {
                    status['status'] = 'Success';
                    status['comment'] = 'User Created Successfully';
                    this.recordResponse(status);
                },
                error => {
                    status['status'] = 'Failure';
                    if (!error.error) {
                        status['comment'] = 'General Error occurred. Please try again';
                    } else if (error.error.firstName !== undefined) {
                        status['comment'] = error.error.firstName;
                    } else if (error.error.lastName !== undefined) {
                        status['comment'] = error.error.lastName;
                    } else if (error.error['User Name']) {
                        status['comment'] = error.error['User Name'];
                    } else if (error.error.userName !== undefined) {
                        status['comment'] = error.error.userName;
                    } else if (error.error.Username !== undefined) {
                        status['comment'] = error.error.Username;
                    } else if (error.error.email !== undefined) {
                        status['comment'] = error.error.email;
                    } else if (error.error.errors !== undefined) {
                        status['comment'] = error.error.errors[0].defaultMessage;
                    } else {
                        status['comment'] = 'General Error occurred. Please try again';
                    }
                    this.recordResponse(status);
                }
            );
        }
    }

    recordResponse(status:Object) {
        this.statusMsg.push(status);
        if (this.statusMsg.length === (this.totalLines.length - 1)) {
            this.statusMsg.forEach(data => {
                if (data.status === 'Failure') {
                    this.failureCount.push(data);
                } else if (data.status === 'Success') {
                    this.successCount.push(data);
                }
            });
            this.dataSource = new MatTableDataSource<any>(this.statusMsg);
            setTimeout(() => this.dataSource.paginator = this.paginator);
            setTimeout(() => this.dataSource.sort = this.sort);
            this.isLoading = false;
            this.isStatus = false;
            this.usersService.updateUserList.next();
        }
        this.title = this.totalLines
            ? `${this.statusMsg.length} out of ${this.totalLines.length-1} users are processed`
            : '';
    }

    close() {
        if (this.statusMsg.length >= 1) {
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close(false);
        }
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
}
