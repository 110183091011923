import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {HomeService} from "../home/home.service";
import {PowerSearchService} from "../power-search/power-search.service";
import {Alerts} from "../shared/enums/alerts";
import {SharedService} from "../shared/shared.service";

@Component({
    selector: 'app-email-report',
    templateUrl: './email-report.component.html',
    styleUrls: ['./email-report.component.scss']
})

//type MyMap = Record<string, FormControl>

export class EmailReportComponent implements OnInit {
    header = "Share Results"
    title = "Enter up to three emails to receive results"
    destEmail: string;
    loading = false;
    user: any = {};
    count = 1;
    fieldcheck = false;
    options: UntypedFormGroup;
    email1 = new UntypedFormControl('', [Validators.required, Validators.email]);
    email2 = new UntypedFormControl('', [Validators.email]);
    email3 = new UntypedFormControl('', [Validators.email]);
    loggedInUser: any;
    searchResultId: string;
    readonly EMAIL1 = 'email1';
    readonly EMAIL2 = 'email2';
    readonly EMAIL3 = 'email3';
    formMap = new Map();
    linkOnly: boolean

    constructor(@Inject(MAT_DIALOG_DATA) data,
                private fb: UntypedFormBuilder,
                private dialogRef: MatDialogRef<EmailReportComponent>,
                private powerSearchService: PowerSearchService,
                private sharedService: SharedService,
                private homeService: HomeService) {
        this.searchResultId = data.searchResultId;
        this.linkOnly = data.linkOnly;
        this.options = fb.group({
            hideRequired: false,
            floatLabel: 'auto',
        });
        this.formMap.set(this.EMAIL1, this.email1);
        this.formMap.set(this.EMAIL2, this.email2);
        this.formMap.set(this.EMAIL3, this.email3);
        if (homeService.userDetails) {
            this.loggedInUser = JSON.parse(JSON.stringify(homeService.userDetails));
        }
    }

    ngOnInit() {
        if (this.loggedInUser) {
            this.destEmail = this.loggedInUser.email;
        }
    }    

    submit() {
        if (this.email1.valid && this.email2.valid && this.email3.valid) {
            var emails = new Array();
            for (let emailField of [this.email1, this.email2, this.email3]) {
                if (emailField.value == null || emailField.valid) {
                    emails.push(emailField.value);
                }
            }
            this.loading = true;
            this.powerSearchService.emailReport(this.searchResultId, emails, this.linkOnly).subscribe(
                result => {
                    this.loading = false;
                    this.sharedService.showAlert(Alerts.SUCCESS, 'Email has been sent');
                    this.dialogRef.close(true);
                },
                error => {
                    this.loading = false;
                    close();
                    this.sharedService.handleErrors(error);
                }
            );
        }
    }

    close() {
        this.dialogRef.close();
    }

    getErrorMessage(formControl) {
        var field = this.formMap.get(formControl);
        return field.hasError('required') ? 'You must enter a value' :
            field.hasError('pattern') ? 'Not a valid email' : '';
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }
}
