import {Component, OnInit} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { HomeService } from '../home/home.service';
import { LoginService } from '../login/login.service';
import { NavigationService } from '../navigation/navigation.service';
import { Roles } from '../shared/enums/roles';
import { UserTo } from '../shared/models/userTo';
import { SharedService } from '../shared/shared.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { Alerts } from '../shared/enums/alerts';
import { DownloadKmlFileComponent } from './download-kml-file/download-kml-file.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ListParentOrganizationsComponent } from '../organizations/list-parent-organizations/list-parent-organizations.component';
import { OrganizationTo } from '../shared/models/organizationTo';

declare let google: any;

@Component({
    selector: 'app-create-polygon',
    templateUrl: './create-polygon.component.html',
    styleUrls: ['./create-polygon.component.scss']
})
export class CreatePolygonComponent implements OnInit {

    token: string;
    loggedInUser: UserTo;
    isSuperUser: boolean  = false;
    selectedOrg: OrganizationTo;
    orgDialogRef: MatDialogRef<unknown, any>;
    redirectUri: string;

    map: google.maps.Map;
    mapCenter: google.maps.LatLngLiteral;
    mapType: any = google.maps.MapTypeId.ROADMAP;
    mapZoom: number = 5;
    bonds: any = new google.maps.LatLngBounds();
    geoJsonObject: Object;

    coordinates: any = [];
    pointList: { lat: number; lng: number }[] = [];
    drawingManager: any;
    selectedShape: any;
    selectedArea = 0;
    drawingOptions: any = {
        drawingControl: false,
        drawingControlOptions: {
            drawingModes: ['polygon'],
        },
        polygonOptions: {
            draggable: true,
            editable: true,
            strokeColor: '#3f3d3d',
            strokeWeight: 1.5,
            fillColor: '#958a8a',
            fillOpacity: 0.3
        },
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };

    refreshToken: string;
    tokenExpiryTime: number;
    showTokenExpiry: boolean = false;
    subscription: Subscription;
    milliSecondsInASecond: number = 1000;
    hoursInADay: number = 24;
    minutesInAnHour: number = 60;
    SecondsInAMinute: number  = 60;

    secondsToExpire: number;
    minutesToExpire: number;
    hoursToExpire: number;

    constructor(private sharedService: SharedService,
                private loginService: LoginService,
                private homeService: HomeService,
                private navigateService: NavigationService,
                private route: ActivatedRoute,
                public router: Router,
                private dashboardService: DashboardService,
                private dialog: MatDialog) { }

    ngOnInit(): void {
        this.token = this.route.snapshot.queryParamMap.get("token");
        if(this.route.snapshot.queryParamMap?.has("refresh-token")) {
            this.refreshToken = this.route.snapshot.queryParamMap.get("refresh-token");
        }
        if (this.token != null) {
            localStorage.setItem(this.sharedService.ID_TOKEN, this.token);
            this.getUserInfo();
        } else {
            var token = localStorage.getItem(this.sharedService.ID_TOKEN);
            if(token && token !== 'null') {
                this.token = token;   
                this.getUserInfo();                        
            } else {        
                this.redirectUri = window.location.pathname;                    
                this.signIn();
            } 
        }
        
        if (this.refreshToken != null) {
            localStorage.setItem(this.sharedService.REFRESH_TOKEN, this.refreshToken);
        } else {
            var refreshToken = localStorage.getItem(this.sharedService.REFRESH_TOKEN);
            if (refreshToken && refreshToken !== 'null') {
                this.refreshToken = refreshToken;                           
            }
        }

        this.router.navigate([], {
            queryParams: {
                'token': null,
                'refresh-token': null
            },
            queryParamsHandling: 'merge'
        });
        this.checkTokenExpired();

        this.mapCenter =  {         // current coordinates and passing into
            lat: 38.0,              // current location
            lng: 97.0,
        };
    }

    selectOrg() {
        this.orgDialogRef = this.dialog.open(ListParentOrganizationsComponent, {
            data: this.selectedOrg?.clientId
        });
        this.orgDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.selectedOrg = result;
                this.fetchOrgBoundaries(this.selectedOrg?.clientId);
            }
        });
    }

    ngOnDestroy() {
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear();
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].clear();
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onMapReady(map) {
        this.map = map;
        this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('mapCenter'));
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('mapActions'));
        this.initDrawingManager(map);
    }

    initDrawingManager = (map: any) => {
        const self = this;
        this.drawingManager = new google.maps.drawing.DrawingManager(this.drawingOptions);
        this.drawingManager.setMap(map);
        google.maps.event.addListener(
            this.drawingManager,
            'overlaycomplete',
            (event) => {
                if (event.type === google.maps.drawing.OverlayType.POLYGON) {
                    const paths = event.overlay.getPaths();
                    for (let p = 0; p < paths.getLength(); p++) {
                        google.maps.event.addListener(
                            paths.getAt(p),
                            'set_at',
                            () => {
                                if (!event.overlay.drag) {
                                    self.updatePointList(event.overlay.getPath());
                                }
                            }
                        );
                        google.maps.event.addListener(
                            paths.getAt(p),
                            'insert_at',
                            () => {
                                self.updatePointList(event.overlay.getPath());
                            }
                        );
                        google.maps.event.addListener(
                            paths.getAt(p),
                            'remove_at',
                            () => {
                                self.updatePointList(event.overlay.getPath());
                            }
                        );
                    }
                    self.updatePointList(event.overlay.getPath());
                    this.selectedShape = event.overlay;
                    this.selectedShape.type = event.type;
                }
                if (event.type !== google.maps.drawing.OverlayType.MARKER) {
                    // Switch back to non-drawing mode after drawing a shape.
                    self.drawingManager.setDrawingMode(null);
                    // To hide:
                    self.drawingManager.setOptions({
                        drawingControl: false,
                    });
                }
            }
        );
    }

    deleteSelectedShape() {
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
            this.selectedArea = 0;
            this.pointList = [];
            // To show:
            this.drawingManager.setOptions(this.drawingOptions);
        }
    }
    
    updatePointList(path) {
        this.pointList = [];
        const len = path.getLength();
        for (let i = 0; i < len; i++) {
            this.pointList.push(path.getAt(i).toJSON());
        }
        this.selectedArea = google.maps.geometry.spherical.computeArea(path);
    }

    centerMap() {
        this.map.fitBounds(this.bonds, 50);
    }

    styleFunc() {
        return ({
            clickable: false,
            fillColor: "#A0A070",
            fillOpacity: 0.12,
            strokeColor: "black",
            strokeWeight: 0.5
        });
    }

    getUserInfo() {
        this.homeService.getUserInfo().subscribe(data => {
            this.sharedService.setLoggedInUser(data);
            this.loggedInUser = data;
            this.isSuperUser = this.loggedInUser?.roles.includes(Roles.SUPER);
            this.selectedOrg = new OrganizationTo(this.loggedInUser.org);
            this.fetchOrgBoundaries(this.selectedOrg.clientId);
        });
    }

    signOut() {
        localStorage.setItem(this.sharedService.ID_TOKEN, null);
        this.loginService.signOut();
    }
    
    signIn() {
        if (this.redirectUri) {
            this.loginService.signInAndRedirect(this.redirectUri);
        } else {
            this.loginService.signIn();
        }
    }

    clearMapData() {
        this.geoJsonObject = null;
        this.bonds = new google.maps.LatLngBounds();
        if(this.map) {
            this.map.setCenter({lat: 38.0, lng: -97});
            this.map.setZoom(5);
            this.clearGeofenceDataFromMap();
        }
    }

    clearGeofenceDataFromMap() {
        this.map.data.setStyle({});
        this.map.data.forEach(feature => {
            this.map.data.remove(feature);
        });
    }

    fetchOrgBoundaries(orgId) {
        this.clearMapData();
        this.dashboardService.fetchOrgBoundaries(orgId).subscribe(
            data => {
                this.geoJsonObject = {
                    "type": "FeatureCollection",
                    "features": [
                        {
                            "type": "Feature",
                            "geometry": data
                        }
                    ]
                };

                this.map.data.addGeoJson(this.geoJsonObject);
                this.map.data.setStyle(this.styleFunc());
                this.adjustBounds(data);
            },
            error => {
                this.sharedService.showAlert(Alerts.ERROR, error.error.message);
            }
        );
    }

    adjustBounds(data: any) {
        if(data.type.toLowerCase() === 'multipolygon') {
            data.coordinates.forEach(multipolygon => {
                multipolygon.forEach(polygon => {
                    polygon.forEach(coordinates => {
                        this.bonds.extend(new google.maps.LatLng(+coordinates[1], +coordinates[0]));
                    });
                });
            });
        } else if(data.type.toLowerCase() === 'polygon') {
            data.coordinates.forEach(polygon => {
                polygon.forEach(coordinates => {
                    this.bonds.extend(new google.maps.LatLng(+coordinates[1], +coordinates[0]));
                });
            });
        }
        this.map.fitBounds(this.bonds);
    }

    openDialog() {
        this.coordinates = [];
        for (let point of this.pointList) {
            this.coordinates.push(point);
        }
        let length = this.coordinates.length;
        if(length > 0 && this.coordinates[0].lat !== this.coordinates[length-1].lat &&
                         this.coordinates[0].lng !== this.coordinates[length-1].lng) {
            this.coordinates.push(this.coordinates[0]);
        }
        const dialogRef = this.dialog.open(DownloadKmlFileComponent, {
            data: this.coordinates,
            width: '450px'
        });
        dialogRef.afterClosed().subscribe(result => {
            this.coordinates = [];
        });
    }

    checkTokenExpired() {
        const jwt = localStorage.getItem(this.sharedService.ID_TOKEN)?.split('.');
        this.tokenExpiryTime = 1000 * (JSON.parse(atob(jwt[1])).exp || 0);
        if ((jwt?.length > 1) && this.tokenExpiryTime > Date.now()) {
            this.subscription = interval(1000)
                .subscribe(x => { 
                    let timeDifference = this.tokenExpiryTime - new  Date().getTime();
                    this.allocateTimeUnits(timeDifference);
                });
        } else {
            localStorage.setItem(this.sharedService.ID_TOKEN, null);
            localStorage.setItem(this.sharedService.REFRESH_TOKEN, null);
            this.loginService.signOut();
        }
    }

    allocateTimeUnits (timeDifference: number) {
        if(timeDifference <= 0) {
            localStorage.setItem(this.sharedService.ID_TOKEN, null);
            localStorage.setItem(this.sharedService.REFRESH_TOKEN, null);
            this.loginService.signOut();
        }
        this.secondsToExpire = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
        this.minutesToExpire = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
        this.hoursToExpire = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
        
        if(this.hoursToExpire === 0 && this.minutesToExpire < 30) {
            this.showTokenExpiry = true;
        }
    }

    refreshAccessToken() {
        if (this.refreshToken === null) {
            this.refreshToken = localStorage.getItem(this.sharedService.REFRESH_TOKEN);   
        }
        if (this.refreshToken === null) {
            this.sharedService.showAlert(Alerts.ERROR, "Refresh token is missing. Please make a new SignIn request");
        } else {
            Swal.fire({
                title: 'Please Wait...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            this.homeService.refreshAccessToken({refreshToken:this.refreshToken}).subscribe(data => {
                Swal.close();
                this.token = data.accessToken;
                this.refreshToken = data.refreshToken;
                localStorage.setItem(this.sharedService.ID_TOKEN, this.token);
                localStorage.setItem(this.sharedService.REFRESH_TOKEN, this.refreshToken);
                const jwt = this.token.split('.');
                this.tokenExpiryTime = 1000 * (JSON.parse(atob(jwt[1])).exp || 0);
                this.sharedService.showAlert(Alerts.SUCCESS, "Your session has been extended successfully");
                this.showTokenExpiry = false;
            },
            error => {
                Swal.close();
                this.sharedService.showAlert(Alerts.ERROR, error.error.message);
            });
        }
    }

    displayExpiryTime() {
        let timeString: string = "";
        if(this.hoursToExpire > 0) {
            let hourString = this.hoursToExpire.toString(); 
            timeString += (hourString.length === 1 ? "0"+hourString : hourString) + ":";
        }
        let minutesString = this.minutesToExpire.toString(); 
        timeString += (minutesString.length === 1 ? "0"+minutesString : minutesString) + ":";

        let secondsString = this.secondsToExpire.toString(); 
        timeString += secondsString.length === 1 ? "0"+secondsString : secondsString;
        
        return timeString;
    }

}
