import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationService } from '../../navigation/navigation.service';
import { HomeService } from '../../home/home.service';
import { Roles } from '../../shared/enums/roles';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { EntitlementTo } from 'src/app/shared/models/entitlementTo';
import { EntitlementsService } from '../entitlements.service';
import { CreateEntitlementsComponent } from '../create-entitlements/create-entitlements.component';
import { UpdateEntitlementsComponent } from '../update-entitlements/update-entitlements.component';
import { SearchTypeTo } from 'src/app/shared/models/searchTypeTo';
import { SearchTypesService } from 'src/app/search-types/search-type.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-list-entitlements',
    templateUrl: './list-entitlements.component.html',
    styleUrls: ['./list-entitlements.component.scss']
})
export class ListEntitlementsComponent implements OnInit {

    dialogRef;
    entitlementList: EntitlementTo[] = [];
    filteredList: EntitlementTo[] = [];
    searchTypeList: SearchTypeTo[] = [];
    displayedColumns: string[] = ['name', 'displayName', 'options'];
    dataSource = new MatTableDataSource<EntitlementTo>(this.entitlementList);
    isLoading = false;
    isDeletingEntitlement = false;
    displayFailureMsg = false;
    errorMsg: Map<string, string>;
    selection = new SelectionModel<EntitlementTo>(true, []);
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    paginatorLength: number;
    enabled: Boolean;
    userRole = '';
    roles = Roles;
    private entitlementSubscription: Subscription;
    selectedOrgTypes: Array<string> = ['INTERNAL', 'PROVIDER', 'PSAP'];
    form: UntypedFormGroup;

    constructor(public dialog: MatDialog,
        public navigation: NavigationService,
        public entitlementService: EntitlementsService,
        public sharedService: SharedService,
        public homeService: HomeService,
        private cdref: ChangeDetectorRef,
        private searchTypesService: SearchTypesService,
        private fb: UntypedFormBuilder) {
        this.entitlementSubscription = this.entitlementService.updateEntitlementList$.subscribe(
            () => {
                this.getAllEntitlements();
            });
        this.form = this.fb.group({
            checkArray: this.fb.array([])
        })
    }

    ngOnInit() {
        this.getAllSearchTypes();
        this.getAllEntitlements();
        this.getLoggedInUserRole(this.homeService.userDetails);
    }


    getLoggedInUserRole(user) {
        this.userRole = (user.roles.includes(this.roles.SUPER)) ? this.roles.SUPER :
            (user.roles.includes(this.roles.ADMIN)) ? this.roles.ADMIN : this.roles.SEARCH;
    }


    getAllEntitlements() {
        this.isLoading = true;
        this.entitlementService.getAllEntitlementDef().subscribe(data => {
            this.entitlementList = data;
            this.filterListByOrgType();
        }, error => {
            this.displayFailureMsg = true;
            this.isLoading = false;
        });
    }

    getAllSearchTypes() {
        this.isLoading = true;
        this.searchTypesService.getAllSearchTypes().subscribe(data => {
            this.searchTypeList = data;
        }, error => {
            this.displayFailureMsg = true;
            this.isLoading = false;
        });
    }


    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }

    setEntitlementList() {
        if (this.filteredList.length > 20) {
            this.paginatorLength = this.filteredList.length;
        } else {
            this.paginatorLength = 20;
        }

        const currentfilter = this.dataSource.filter;
        this.dataSource = new MatTableDataSource<EntitlementTo>(this.filteredList);
        this.dataSource.filter = currentfilter;
        this.dataSource.filterPredicate = (entitlementTo: EntitlementTo, filter: string) => 
            (entitlementTo.name.trim().toLowerCase().indexOf(filter) !== -1) ||
            (entitlementTo.displayName.trim().toLowerCase().indexOf(filter) !== -1);
            
        setTimeout(() => this.dataSource.paginator = this.paginator);

        this.dataSource.sortingDataAccessor = (entitlementTo: EntitlementTo, sortHeaderId: string): string => {
            if (typeof entitlementTo[sortHeaderId] === 'string') {
                return entitlementTo[sortHeaderId].toLowerCase();
            }
            return entitlementTo[sortHeaderId];
        };
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        this.displayFailureMsg = false;
    }

    ngOnDestroy() {
        this.entitlementSubscription.unsubscribe();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    onValChange(e) {
        if (e.source._checked && !this.selectedOrgTypes.includes(e.value)) {
            this.selectedOrgTypes.push(e.value)
        } else if (!e.source._checked && this.selectedOrgTypes.includes(e.value)) {
            let index = this.selectedOrgTypes.findIndex(orgType => orgType === e.value);
            this.selectedOrgTypes.splice(index, 1);
        }
        this.filterListByOrgType();
    }

    filterListByOrgType() {
        this.filteredList = this.entitlementList.filter(entitlement => this.selectedOrgTypes.includes(entitlement.orgType));
        this.setEntitlementList();
    }

    createEntitlement() {
        this.dialogRef = this.dialog.open(CreateEntitlementsComponent);
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAllEntitlements();
            }
        });
    }

    editEntitlements(object) {
        this.dialogRef = this.dialog.open(UpdateEntitlementsComponent, {
            data: object,
            width: '550px'
        });
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAllEntitlements();
            }
        });
    }

    deleteEntitlement(object: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Delete the Entitlement "' + object.name + '"',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'No, cancel',
            showLoaderOnConfirm: true
        }).then((result: any) => {
            if (result.value) {
                this.isDeletingEntitlement = true;

                let mappedSearchTypes: Array<string> = [];
                this.searchTypeList.forEach(searchType => {
                    let isMapped: boolean = false;
                    if (searchType.entitlement === object.name) {
                        isMapped = true;
                    }
                    if (isMapped) {
                        mappedSearchTypes.push(searchType.name);
                    }
                });

                if (mappedSearchTypes.length === 0) {
                    this.entitlementService.deleteEntitlement(object.name).subscribe(
                        data => {
                            this.isDeletingEntitlement = false;
                            this.sharedService.showAlert('success', 'Entitlement ' + object.name + ' has been deleted.');
                            this.entitlementService.updateEntitlementList.next();
                        },
                        error => {
                            this.isDeletingEntitlement = false;
                            if (error.error) {
                                this.sharedService.showConfirmAlert('error', error.error);
                            } else {
                                this.sharedService.showAlert('error', 'Entitlement ' + object.name + ' has not been deleted.');
                            }
                            this.entitlementService.updateEntitlementList.next();
                        }
                    );
                } else {
                    this.isDeletingEntitlement = false;
                    this.sharedService.showConfirmAlert('error', "Could not delete '" + object.name + "' as it has already been mapped to below search types, [" + mappedSearchTypes + "]");
                }
            }
        });
    }

    syncPrimaryPaginator(event: PageEvent) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.page.emit(event);
    }

}
