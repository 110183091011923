import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {ProvidersService} from '../provider.service';
import {SharedService} from '../../shared/shared.service';
import {SearchTypesService} from '../../search-types/search-type.service';
import {Alerts} from '../../shared/enums/alerts';
import {ProviderTo} from 'src/app/shared/models/providerTo';
import {ProviderBase} from "../provider-base";
import { EntitlementsService } from 'src/app/entitlements/entitlements.service';
import { EntitlementTo } from 'src/app/shared/models/entitlementTo';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SupplementalDataProviderTo } from 'src/app/shared/models/supplementalDataProviderTo';

@Component({
    selector: 'app-create-provider',
    templateUrl: './create-provider.component.html',
    styleUrls: ['./create-provider.component.scss']
})
export class CreateProviderComponent extends ProviderBase implements OnInit {
    
    @ViewChild('autosize') autosize: CdkTextareaAutosize;
    
    options: UntypedFormGroup;
    resetData : string;
    loading: boolean = false;
    preferenceList: Array<String> = [];
    entitlementList: Array<EntitlementTo> = [];

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private fb: UntypedFormBuilder,
                private providersService: ProvidersService, 
                public dialogRef: MatDialogRef<CreateProviderComponent>,
                protected sharedService: SharedService,
                private searchTypesService: SearchTypesService,
                public entitlementService: EntitlementsService) {
        super(sharedService);
        this.options = this.fb.group({
            hideRequired: false,
            floatLabel: 'auto'
        });
    }

    ngOnInit() {
        this.provider = new ProviderTo();
        this.resetData = this.data.selectedSearchTypeName;
        if (this.resetData !== 'ALL') {
            this.provider.searchTypeName = this.resetData;
        }
        this.provider.requestHeaders = [];
        this.provider.oauth2TokenHeaders = [];
        this.provider.supplementalDataProviders = [];
        this.supplementalDataProvidersDataSource = new MatTableDataSource<SupplementalDataProviderTo>(this.provider.supplementalDataProviders);
        this.getAllEntitlements();
        this.getSupplementalDataProviders(this.data.supplementalDataProviders);
    }

    getAllEntitlements() {
        this.entitlementService.getAllEntitlementDef().subscribe (data => {
           this.entitlementList = data; 
           this.getAllSearchTypes();
        }, error => {
                this.loading = false;
                this.dialogRef.close(false);
                this.sharedService.handleErrors(error);               
        });   
    }

    getSupplementalDataProviders(supplementalDataProviders: any) {
        if(supplementalDataProviders) {
            this.supplementalProviders = [];
            supplementalDataProviders.forEach(provider => {
                this.supplementalProviders.push(new ProviderTo(provider));
            });
        }
    }

    getAllSearchTypes() {
        this.searchTypesService.getAllSearchTypes().subscribe(data => {
            this.searchTypeList = data;
            this.searchTypeList.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
            this.getMappedPreferences(this.provider.searchTypeName);
        }, error => {
            this.loading = false;
            this.dialogRef.close(false);
            this.sharedService.handleErrors(error);
        });
    }

    getMappedPreferences(selectedSearchTypeName: String) {     
        if(selectedSearchTypeName) {
            let mappedEntitlement: String;            
            this.searchTypeList.forEach(searchType => {
                if(searchType.name === selectedSearchTypeName) {
                    mappedEntitlement = searchType.entitlement;
                }
            });
          
            this.entitlementList.forEach(entitlement => {
                if(mappedEntitlement === entitlement.name) {
                    this.preferenceList = entitlement.preferences;
                }
            });                              
        }        
    }

    change(event) {
        if (event.srcElement.value !== '') {
            this.count = this.count + 1;
        } else if (event.srcElement.value === '') {
            this.count = this.count - 1;
        }
        if (this.count <= 1 || this.fieldcheck === false) {
            this.fieldcheck = !this.fieldcheck;
        }
    }

    createProvider() {
        if(!this.isValidData(this.provider, true)) {
            return;
        }
        this.checkAuthType();
        this.loading = true;
        this.providersService.createProvider(this.provider).subscribe(
            data => {
                this.loading = false;
                this.sharedService.showAlert(Alerts.SUCCESS, 'Provider created successfully');
                this.dialogRef.close(true);
            },
            error => {
                this.loading = false;
                this.dialogRef.close(false);
                this.sharedService.handleErrors(error,
                    [
                        {"condition": (error.status == 400 && error.error["Provider Name"]), "msg": "Provider Name already exists"}
                    ], "Unable to create provider");
            }
        );
    }

    resetProvider() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.provider = new ProviderTo();
                    this.provider.searchTypeName = this.resetData;
                    this.fieldcheck = false;
                }
            });
        } else {
            this.provider = new ProviderTo();
            this.provider.searchTypeName = this.resetData;
        }
    }

    close() {
        if (this.fieldcheck === true) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }
}