import {Component, Inject, OnInit, TemplateRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {OrganizationTo} from '../../shared/models/organizationTo';
import {OrganizationsService} from '../organizations.service';
import {SharedService} from '../../shared/shared.service';
import {Alerts} from '../../shared/enums/alerts';
import {UsersService} from "../../users/users.service";
import {Roles} from "../../shared/enums/roles";
import {HomeService} from '../../home/home.service';
import {OrganizationBase} from "../OrganizationBase";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {ListParentOrganizationsComponent} from "../list-parent-organizations/list-parent-organizations.component";

@Component({
    selector: 'app-update-organization',
    templateUrl: './update-organization.component.html',
    styleUrls: ['./update-organization.component.scss']
})
export class UpdateOrganizationComponent extends OrganizationBase implements OnInit {

    organization: OrganizationTo;
    resetData: OrganizationTo;
    roles = Roles;
    header = "Update Organization";
    secretHeader = "Secret"
    title: string;
    orgDialogRef: MatDialogRef<unknown, any>;
    secret:string;
    secretDialog: MatDialogRef<unknown, any>;

    constructor(@Inject(MAT_DIALOG_DATA) public data : OrganizationTo,
                public dialogRef: MatDialogRef<UpdateOrganizationComponent>,
                public sharedService: SharedService,
                public organizationsService: OrganizationsService,
                public usersService: UsersService,
                public homeService: HomeService,
                private dialog: MatDialog) {
        super();
    }

    ngOnInit() {
        this.organization = new OrganizationTo(this.data);
        this.resetData = new OrganizationTo(this.organization);
        this.header = "Update Organization";
        this.title = this.organization.name;
        if (this.organization.parentClientId) {
            this.organizationsService.getOrgByClientId(this.organization.parentClientId).subscribe(parentOrg => {
                this.parentOrgName = parentOrg.name;
            })
        }
    }

    updateOrganization() {
        if (this.name.valid && this.clientId.valid && this.organization.type) {
            if (this.resetData.name !== this.organization.name ||
                this.resetData.clientId !== this.organization.clientId ||
                this.resetData.type !== this.organization.type ||
                this.resetData.parentClientId !== this.organization.parentClientId) {
                this.loading = true;
                if(this.resetData.type !== this.organization.type){
                    this.organization.entitlements = [];
                } 
                this.updateOrganizationDetails();
            } else {
                this.sharedService.showAlert(Alerts.ERROR, 'No changes have been made');
            }
        } 
    }

    onValChange(val : string) {
        this.organization.type = val;
    }

    clear() {
        this.organization.parentClientId = undefined;
        this.parentOrgName = undefined;
        this.fieldcheck = true;
    }

    selectParentOrg() {
        this.orgDialogRef = this.dialog.open(ListParentOrganizationsComponent, {
            data: this.organization.parentClientId 
        });
        this.orgDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.organization.parentClientId = result.clientId;
                this.parentOrgName = result.name;
                this.fieldcheck = true;
            }
        });
    }

    resetOrganization() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will be lost!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.organization = new OrganizationTo(this.resetData);
                    this.parentOrgName = this.organization.name;
                }
            });
        } else {
            this.organization = new OrganizationTo(this.resetData);
            this.parentOrgName = this.organization.name;
        }
    }

    close() {
        if (this.fieldcheck) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your changes will not be saved !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes, Close',
                cancelButtonText: 'No, Cancel',
            }).then((result) => {
                if (result.value) {
                    this.dialogRef.close(false);
                }
            });
        } else {
            this.dialogRef.close(false);
        }
    }

    updateOrganizationDetails() {
        if (this.resetData.type !== this.organization.type) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Your entitlements and preferences will be deleted',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.value) {
                    this.updateOrg();
                } else {
                    this.dialogRef.close(true);
                }
            });
        } else {
            this.updateOrg();
        }
    }

    updateOrg() {
        this.organizationsService.updateOrganization(this.organization).subscribe(data => {
            this.loading = false;
            this.sharedService.showAlert(Alerts.SUCCESS, 'Organization updated successfully');
            this.dialogRef.close(true);
        }, error => {
            this.loading = false;
            this.sharedService.handleErrors(error,
                [{
                    "condition": (error.status === 400 && Object.keys(error.error).length > 0),
                    "msg": error.error[Object.keys(error.error)[0]]
                }],
                "Unable to update organization");
            this.dialogRef.close(true);
        });
    }

    viewSharedSecret(templateRef: TemplateRef<any>) {
        this.usersService.getSharedSecret(this.organization.uuid).subscribe( data => {
            this.secret = data;
            this.secretDialog = this.dialog.open(templateRef, {width: '650px'});
        })
    }

    closeSecret() {
        this.secretDialog.close();
    }
}
