import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PowerSearchService} from "../power-search.service";
import {SharedService} from "../../shared/shared.service";
import {BrokerSearch} from '../../shared/models/brokerSearch';
import { Alerts } from 'src/app/shared/enums/alerts';
import { HomeService } from 'src/app/home/home.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-power-data-results',
    templateUrl: './power-data-results.component.html',
    styleUrls: ['./power-data-results.component.scss'],
    providers: [PowerSearchService]
})
export class PowerDataResultsComponent implements OnInit {

    readonly COMMERCIAL_ADDRESS_SEARCH: string = "Commercial Address";
    readonly COMMERCIAL_PHONE_SEARCH: string = "Commercial Phone";
    readonly COMMERCIAL_VEHICLE_SEARCH: string = "Commercial Vehicle";
    readonly COMMERCIAL_LOCATE_SEARCH: string = "Commercial Locate";
    readonly SAFETY_SHIELD_SEARCH: string = "Safety Shield";
    readonly DBH_SEARCH: string = "Device-Based Hybrid";
    readonly HOME_DATA_SEARCH: string = "Home Data";
    readonly MEDICAL_DATA_SEARCH: string = "Medical Data";
    readonly MULTIMEDIA_SEARCH: string = "Multimedia Search";
    readonly ALARM_SEARCH: string = "Alarm";

    @Input() securityWord : string;
    type : string;
    incidentId : string;
    uniqueId : string
    authToken : string;
    orgId : string;
    fragment: string;
    incidentResultKeys: string[] = [];
    incidentResults: Map<string, Array<BrokerSearch>> = new Map<string, Array<BrokerSearch>>();
    selectedResult: BrokerSearch;
    pduiSearch: boolean = false;

    constructor(public powerSearchService : PowerSearchService,
                public homeService: HomeService,
                public sharedService: SharedService,
                private activeRouter: ActivatedRoute) { }

    ngOnInit() {
        this.fragment = this.activeRouter.snapshot.fragment;
        var url = this.activeRouter.snapshot.url[0].toString();
        if(url=='appconsole') {
            this.pduiSearch = true;
            this.incidentResults.clear();
            this.setIncidentResults(this.sharedService.getSearchResults());
            this.sortIncidentResults();
            if (!this.selectedResult) {
                this.selectFirstAvailableResult();
            }
        } else if(url=='shortweblink') {
            if(this.fragment && this.fragment.includes("?")) {
                this.fragment = this.fragment.substring(0, this.fragment.indexOf("?"));
            } 
            this.securityWord = this.activeRouter.snapshot.params['securityWord'].toLowerCase();
        }
        
        if(this.securityWord) {
            this.pduiSearch = false;
            this.incidentResults.clear();
            this.powerSearchService.searchSwlResults(this.securityWord, data => {
                this.addResult(data);
            });
        }
    }

    addResult(brokerSearchResult) {
        var brokerSearch = new BrokerSearch().deserialize(brokerSearchResult);
        this.setIncidentResult(brokerSearch);
        this.sortIncidentResults();
        if (this.fragment && brokerSearch.searchType === this.fragment && brokerSearch.dataFound) {
            this.showSelectedResult(brokerSearch);
        }
        if(this.securityWord) {
            this.selectSecuritywordAssociatedResult();
        }
        if (!this.selectedResult) {
            this.selectFirstAvailableResult();
        }
    }

    selectFirstAvailableResult() {
        let firstAvailableResult: BrokerSearch;
        for(let entry of this.incidentResults.entries()) {
            for(let result of entry[1]) {
                if(result.dataFound || this.MULTIMEDIA_SEARCH === result.searchType) {
                    firstAvailableResult = result;
                    break;
                }
            }
            if(firstAvailableResult) {
                break;
            }
        }
        if(firstAvailableResult) {
            this.showSelectedResult(firstAvailableResult);
        }
    }

    selectSecuritywordAssociatedResult() {
        let bs:BrokerSearch;
        for(let entry of this.incidentResults.entries()) {
            for(let result of entry[1]) {
                if(result.dataFound && result.searchResult?.results) {
                    let index = result.searchResult.results.findIndex(
                        rs => (this.securityWord && rs.shortWebLink && rs.shortWebLink.includes(this.securityWord))
                    );
                    if(index !== -1) {
                        bs = result;
                        break;
                    }
                }
            }
            if(bs) {
                break;
            }
        }
        if(bs) {
            this.showSelectedResult(bs);
        }
    }

    getResultsForSearchType(searchType: string) {
        return this.incidentResults.get(searchType);
    }

    setIncidentResults(results: BrokerSearch[]) {
        results.forEach(result => {
            this.setIncidentResult(result);
        });
    }

    setIncidentResult(result: BrokerSearch) {
        let resultList: Array<BrokerSearch>;
        if(this.incidentResults.has(result.searchType)) {
            resultList = this.incidentResults.get(result.searchType);
            resultList.push(result);
            this.sortSearchTypeResults(resultList);
        } else {
            resultList = [result];
        }
        this.incidentResults.set(result.searchType, resultList);
    }

    sortSearchTypeResults(results: BrokerSearch[]) {
        results.sort((r1, r2) => {
            return r1.timestamp > r2.timestamp ? -1 : (r1.timestamp < r2.timestamp ? 1 : 0);
        });
    }

    sortIncidentResults() {
        this.incidentResultKeys = Array.from(this.incidentResults.keys()).sort( (k1,k2) => {
            return k1.localeCompare(k2);
        });
        
        this.incidentResultKeys.sort( (k1,k2) => {
            let k1_errorFound: boolean = false;
            let k2_errorFound: boolean = false;

            let index = this.incidentResults.get(k1).findIndex( result => result.dataFound );
            let k1_dataFound: boolean = (index !== -1);

            index = this.incidentResults.get(k2).findIndex( result => result.dataFound );
            let k2_dataFound: boolean = (index !== -1);

            if(!k1_dataFound) {
                index = this.incidentResults.get(k1).findIndex( result => !result.errorFound );
                k1_errorFound = (index === -1);
            }

            if(!k2_dataFound) {
                index = this.incidentResults.get(k2).findIndex( result => !result.errorFound );
                k2_errorFound = (index === -1);
            }

            index = this.incidentResults.get(k1).findIndex( result => result.sensitiveData);
            let k1_sensitiveData: boolean = (index !== -1);
            index = this.incidentResults.get(k2).findIndex( result => result.sensitiveData);
            let k2_sensitiveData: boolean = (index !== -1);

            if(k1_sensitiveData && !k2_sensitiveData &&
                k1_dataFound && k2_dataFound &&
                !k1_errorFound && !k2_errorFound) {
                return 1;
            }
            if(!k1_sensitiveData && k2_sensitiveData &&
                k1_dataFound && k2_dataFound &&
                !k1_errorFound && !k2_errorFound) {
                return -1;
            }
            if(k1_sensitiveData && k2_sensitiveData &&
                k1_dataFound && k2_dataFound &&
                !k1_errorFound && !k2_errorFound) {
                return 0;
            }

            if (k1_dataFound && !k2_dataFound) {
                return -1;
            }                
            if (k2_dataFound && !k1_dataFound) {
                return 1;
            }
            if (k1_dataFound && k2_dataFound) {
                return 0;
            }                
            if (!k1_dataFound && !k2_dataFound && k1_errorFound && !k2_errorFound) {
                return 1;
            }                
            if (!k1_dataFound && !k2_dataFound && k2_errorFound && !k1_errorFound) {
                return -1;
            }      
            return 0;
        });
    }

    isSensitiveData(searchType: string) {
        let index = this.incidentResults.has(searchType) ? 
                    this.incidentResults.get(searchType).findIndex( result => result.sensitiveData ) : -1;
        return index != -1;
    }

    isDataFound(searchType: string) {
        let index = this.incidentResults.has(searchType) ? 
                    this.incidentResults.get(searchType).findIndex( result => result.dataFound ) :  -1;
        return index != -1 || this.MULTIMEDIA_SEARCH === searchType;
    }

    isErrorFound(searchType: string) {
        let dataFound: boolean = this.isDataFound(searchType);
        let errorFound: boolean = false;
        if(!dataFound) {
            let index = this.incidentResults.has(searchType) ? 
                        this.incidentResults.get(searchType).findIndex( result => !result.errorFound ) : -1;
            errorFound = index == -1;
        }
        return errorFound;
    }

    onClickShowResult(searchType: string) {
        let firstAvailableResult: BrokerSearch;
        for(let result of this.incidentResults.get(searchType)) {
            if(result.dataFound || this.MULTIMEDIA_SEARCH === result.searchType) {
                firstAvailableResult = result;
                break;
            }
        }
        if(firstAvailableResult) {
            this.showSelectedResult(firstAvailableResult);
        }
    }

    showSelectedResult(searchResult: BrokerSearch) {
        if(!this.selectedResult || this.selectedResult.uniqueSearchId !== searchResult.uniqueSearchId) {
            if(searchResult.sensitiveData && !searchResult.sensitiveDataSearchDone) {
                this.doSensitiveDataSearch(searchResult);
            } else if(searchResult.dataFound || this.MULTIMEDIA_SEARCH === searchResult.searchType){
                this.selectedResult = searchResult;  
            }
        }
    }

    doSensitiveDataSearch(searchData: BrokerSearch) {
        Swal.fire({
            title: 'Please Wait...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        this.powerSearchService.sensitiveDataSearchForId(searchData.uniqueSearchId).subscribe(data => {
            Swal.close();
            this.showSensitiveResult(searchData, data);
        }, error => {
            Swal.close();
            this.sharedService.showAlert(Alerts.ERROR, error.error);
        });
    }

    showSensitiveResult(searchData: BrokerSearch, sensitiveData: any) {
        let bs = new BrokerSearch().deserialize(sensitiveData);
        bs.sensitiveDataSearchDone = true;
        searchData = bs;
        let index = this.incidentResults.get(bs.searchType).findIndex(
            result => (result.uniqueSearchId === bs.uniqueSearchId)
        );
        if(index !== -1) {
            this.incidentResults.get(bs.searchType)[index] = searchData;
        }
        if(searchData.dataFound){
            this.selectedResult = searchData;  
        } else if(!searchData.dataFound && !searchData.errorFound) {
            this.sharedService.showAlert(Alerts.ERROR, "No data found");
        } else if(!searchData.dataFound && searchData.errorFound) {
            this.sharedService.showAlert(Alerts.ERROR, searchData.errorText);
        }
    }

}
